import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import './SliderMenuTop.scss';
import 'swiper/css/pagination';
import SliderMenuTopItem from './slider-menu-top-item/SliderMenuTopItem';
import { IMenuItem, menuItems } from '../slider-menu/SliderMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const SliderMenuTop: React.FC = () => {
  const { pathname, search } = useLocation();
  const { user } = useSelector((state: RootState) => state.userData);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [swiper, setSwiper] = useState<any>();
  const initializedRef = useRef(false);
  const [isInitialisation, setIsInitialisation] = useState(true);

  const filteredMenuItems = menuItems.filter((item) =>
    !item.role || item.role.some((role) => user?.roles?.includes(role))
  );

  const getItemIndexFromPath = () => {
    const matchedItem = filteredMenuItems.find((item: IMenuItem) =>
      pathname.startsWith(item.url)
    );

    return matchedItem ? filteredMenuItems.indexOf(matchedItem) : 0;
  };

  const initialSlide = getItemIndexFromPath();

  useEffect(() => {
    if (swiper && !swiper.destroyed && swiper.params && !isInitialisation) {
      const itemIndex = getItemIndexFromPath();

      if (itemIndex !== undefined) {
        swiper.slideToLoop(itemIndex, 0);
      }
    }
  }, [swiper, pathname, isInitialisation]);

  const handleNavigate = (realIndex) => {

    const menuItem = filteredMenuItems[realIndex];

    if (menuItem) {
      let newPathname = menuItem.url;

      if (pathname.startsWith(menuItem.url)) {
        newPathname = pathname;
      }

      navigate({ pathname: newPathname, search });
    }

  };

  const debounce = (func, delay) => {
    let timeoutId;

    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleOnSlideChange = (e) => {
    handleNavigate(e.realIndex);
  };

  const debouncedHandleOnSlideChange = debounce(handleOnSlideChange, 500);

  function handleInitialSlideChange() {
    if (isInitialisation) {
      setIsInitialisation(false);
      initializedRef.current = true;
    }
  }

  return (
    <div className="slider-menu-container">
      <Swiper
        slidesPerView={3}
        spaceBetween={1}
        initialSlide={initialSlide}
        centeredSlides
        loop
        onSlideChange={(swiper) => {
          if (!initializedRef.current) {
            handleInitialSlideChange();
          } else {
            debouncedHandleOnSlideChange(swiper);
          }
        }}
        navigation
        onSwiper={setSwiper}
        modules={[Pagination, Navigation]}
      >
        {filteredMenuItems.map((item) => (
          <SwiperSlide key={item.index}>
            <SliderMenuTopItem {...item} menuItems={filteredMenuItems} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default React.memo(SliderMenuTop);
