import { IData, IFilterForm } from 'interfaces/selectClient';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { login } from 'store/reducers/useSlices';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import useLogOut from 'utils/hooks/useLogOut';
import ManagerGroupSelectClientView from './ManagerGroupSelectClient.view';


type Props = {};

const ManagerGroupSelectClient: React.FC<Props> = () => {
  const http = useHttp();
  // const [curentPage, setCurentPage] = useState<number>(0);
  // const [totalPage, setTotalPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSaveClient, setLoadingSaveClient] = useState<boolean>(false);
  const [infoClients, setInfoClients] = useState<IData[]>([]);

  const [clientIdForSpinner, setClientIdForSpinner] = useState<number>(Number(localStorage.getItem('clientId')) || 0);
  // const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const logout = useLogOut();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchClients({});

  }, []);


  const fetchClients = async (param: IFilterForm) => {
    setLoading(true);
    const responseClient = await http.managerGroupSelectClinetHttp.getClients(param);

    // setCurentPage(Number(searchParams.get('page')));
    // setTotalPage(responseClient.data.total_pages);
    setInfoClients(responseClient.data.data);
    setLoading(false);

  };


  const handleSelectClient = async (clientId: number) => {


    setClientIdForSpinner(clientId);
    setLoadingSaveClient(true);
    localStorage.setItem('clientId', String(clientId));
    await http.managerGroupSelectClinetHttp.saveClient(clientId);
    const user = await http.user.infoUser();

    localStorage.setItem('userData', JSON.stringify(user.data));
    dispatch(login(user.data));
    setLoadingSaveClient(false);
    navigate('/');
    // dispatch(resetTariffs());
  };

  return (
    <ManagerGroupSelectClientView
      // curentPage={curentPage}
      // totalPage={totalPage}
      infoClients={infoClients}
      // handlePageClick={handlePageClick}
      loading={loading}
      // optionsZoneCommercials={optionsZoneCommercials()}
      // optionsCountries={optionsCountries()}
      // handleSubmit={handleSubmit}
      // onSubmit={onSubmit}
      // register={register}
      // resetFilter={resetFilter}
      // control={control}
      // valueCountries={valueCountries}
      // setValueCountries={setValueCountries}
      // valueZoneCommercials={valueZoneCommercials}
      // setValueZoneCommercials={setValueZoneCommercials}
      handleSelectClient={handleSelectClient}
      loadingSaveClient={loadingSaveClient}
      clientIdForSpinner={clientIdForSpinner}
      logout={logout}
    />
  );
};

ManagerGroupSelectClient.displayName = 'SelectClient';

export default ManagerGroupSelectClient;
