import { faCartFlatbed } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import { removeImportReferenceData } from 'store/reducers/importReferenceSlice';
import { useDispatch } from 'react-redux';
import useHttp from 'utils/hooks/useHttp';
import { IProformaResponse, IUploadFileProformaResponse } from 'utils/hooks/http/proforma.http';
import { notify } from 'utils/marketplace';
import Modal from 'components/modal/Modal';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { Trans, useTranslation } from 'react-i18next';
import { IParts } from 'interfaces/price';

import './ProformaNewCss.scss';
import ReferenceTable from '../../espace-automotor-order/order-summary/reference-table/ReferenceTable';
import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import OrderModal from 'applications/automotor/components/OrderModal/OrderModal';

type Props = {
  data,
  is_submit_quotation?: number
  deliveryDate?
  checkRestrictionParts?
  submitCountryRestriction?: boolean
};


const ProformaNew: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const http = useHttp();
  const { t } = useTranslation();

  const [loadingProforma, setLoadingProforma] = useState(false);
  const [resData, setResData] = useState<IProformaResponse>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalValidate, setOpenModalValidate] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function calcOffset(date) {
    if (date) {
      const offset = date.getTimezoneOffset();

      date = new Date(date.getTime() - (offset * 60 * 1000));

      return date?.toISOString().split('T')[0];
    }
  }

  const checkOpenValidateModal = async () => {

    if (!props.data.length) {
      notify(t('_empty_data'), 'error');
      setLoadingProforma(false);

      return;
    }

    if (props.is_submit_quotation == 1) {
      proforma();
    } else {

      let validateProforma = true;

      const validate_ind_franco = {};

      await props.data.map(items => {

        let total_provider = 0;

        let ind_equivalence_select = false;

        items.parts.map(i => {
          if (!i.ind_equivalence_select && items.name != 'NOT_SUPPLIED') {
            if (Number(i.quantity) >= i.min_quantity && (Number(i.quantity) % i.uv) == 0) {
              total_provider += i.total;
              ind_equivalence_select = true;
            }
          }

          if (i.ind_equivalence_select && items.name != 'NOT_SUPPLIED') {
            if (!Object.keys(validate_ind_franco).some(key => key === i.ind_equivalence.company)) {
              validate_ind_franco[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
            } else {
              validate_ind_franco[i.ind_equivalence.company].total += i.ind_equivalence.total;
            }
          }
        });


        if ((items.parts.length == 1 && ind_equivalence_select) || items.parts.length > 1 && ind_equivalence_select) {


          if ((total_provider < items.franco) && (items.name != 'NOT_SUPPLIED')) {
            validateProforma = false;
          }

          items.parts.map(item => {
            if (item.quantity < item.min_quantity && items.name != 'NOT_SUPPLIED') {
              validateProforma = false;
            }

            if (item.quantity < item.min_quantity && items.name != 'NOT_SUPPLIED') {
              validateProforma = false;
            }

            if (item.max_quantity != null && (item.quantity! > item.max_quantity) && items.name != 'NOT_SUPPLIED') {
              validateProforma = false;
            }

          });
        }


      });

      Object.keys(validate_ind_franco).forEach(key => {

        if (validate_ind_franco[key].total < validate_ind_franco[key].franco) {
          validateProforma = false;
        }

      });
      const hasReplacedReferences = props.data.map(item => item.parts.filter(part => part.replace)).flat().length;

      if (validateProforma) {
        if (hasReplacedReferences) {
          setOpenConfirmModal(true);
        } else {
          proforma();
        }
      } else {
        setOpenModalValidate(true);
      }
    }
  };


  const proforma = async () => {
    let proformaBody;
    let dataValidate;

    setLoadingProforma(true);

    if (props.is_submit_quotation == 1) {
      dataValidate = props.data;

    } else {

      //delete parts  smaller than min_quantity
      const newData = await props.data.map((items) => {
        const result_parts = items.parts.filter(item => {
          return item.ind_equivalence_select
            ? item.ind_equivalence.quantity >= item.ind_equivalence.min_quantity && (item.ind_equivalence.quantity % item.ind_equivalence.uv) == 0
            : item.quantity >= item.min_quantity && (item.quantity % item.uv) == 0;
        });

        const objCopy = { ...items };

        objCopy.parts = result_parts;

        return objCopy;

      });

      //delete supplier if it has no parts
      dataValidate = await newData.filter(item => {
        return item.parts.length !== 0;
      });

      //delete supplier if franco is lower
      // const dataValidate = await newDataSupplier.filter(item => {
      //   const total_provider = item.parts.reduce((acc, curr) => acc + Number(curr.total), 0);

      //   return total_provider >= item.franco;
      // });

    }

    const validate_ind_franco_item = {};

    const sentDataMail = await props.data.map((items) => {

      let total_provider = 0;
      let total_franco = 0;
      let total_franco_error = false;
      const errors: Array<string> = [];


      items.parts.map(i => {
        if (!i.ind_equivalence_select) {
          if (Number(i.quantity) >= i.min_quantity && (Number(i.quantity) % i.uv) == 0) {
            total_provider += i.total;
            total_franco += i.total;
          }

          total_franco_error = true;
        } else {
          total_provider += i.ind_equivalence.total;

          if (!Object.keys(validate_ind_franco_item).some(key => key === i.ind_equivalence.company)) {

            validate_ind_franco_item[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
          } else {
            validate_ind_franco_item[i.ind_equivalence.company].total += i.ind_equivalence.total;
          }
        }

      });

      const objCopy = { ...items };

      delete objCopy.parts;

      if (total_franco < items.franco && items.franco && items.name != 'NOT_SUPPLIED' && total_franco_error) {
        errors.push('Franco Not Valid');
      }

      objCopy.errors = errors;
      const parts = items.parts.map(item => {

        const errornotValid: Array<string> = [];

        if (!item.ind_equivalence_select) {
          if (item.quantity < item.min_quantity && items.name != 'NOT_SUPPLIED') {
            errornotValid.push('Min quantity Not Valid : ' + item.min_quantity);
          }

          if ((item.quantity % item.uv) != 0 && items.name != 'NOT_SUPPLIED') {
            errornotValid.push('Min quantity Valid :' + item.uv);
          }

          const objCopyItem = { ...item };

          objCopyItem.errors = errornotValid;

          return objCopyItem;
        } else {
          if (item.ind_equivalence.quantity < item.ind_equivalence.min_quantity && items.name != 'NOT_SUPPLIED') {
            errornotValid.push('Min quantity Not Valid : ' + item.ind_equivalence.min_quantity);
          }

          if ((item.ind_equivalence.quantity % item.ind_equivalence.uv) != 0 && items.name != 'NOT_SUPPLIED') {
            errornotValid.push('Min quantity Valid :' + item.ind_equivalence.uv);
          }

          const objCopyItem = { ...item };

          objCopyItem.errors = errornotValid;

          return objCopyItem;
        }
      });

      objCopy.parts = parts;
      objCopy.total_parts = total_provider;

      return objCopy;
    });

    const dataValidateMail = await sentDataMail.map(items => {

      const errors: Array<string> = [];

      items.parts.map(i => {
        if (i.ind_equivalence_select) {
          if (validate_ind_franco_item[i.ind_equivalence.company].total < validate_ind_franco_item[i.ind_equivalence.company].franco && validate_ind_franco_item[i.ind_equivalence.company].franco) {
            errors.push('Franco Not Valid (' + i.ind_equivalence.company + ')');
          }
        }

      });

      const objCopy = { ...items };

      objCopy.errors = items.errors.concat(errors).filter((value, index, self) => self.indexOf(value) === index);

      return objCopy;
    });


    const dataParts: IParts[] = [];

    await dataValidate.map((item) => {
      item.parts.map((part) => {

        if (part.ind_equivalence_select) {
          dataParts.push(part.ind_equivalence);
        } else {
          dataParts.push(part);
        }
      });
    });

    let PRICE_OK;

    if (props.is_submit_quotation == 1) {
      PRICE_OK = dataParts.filter(item => item.codfou !== null);
    } else {
      PRICE_OK = dataParts.filter(item => item.price !== null && item.price !== 0);
    }

    if (PRICE_OK.length) {
      proformaBody = PRICE_OK.map((item, index) => {

        return {
          data: [index, '', item.reference_internal ? item.reference_internal : item.reference_full, item.codfou, item.reference_full, '', item.quantity, item.price],
          info: { is_manufacturer: item.source === 'sup' ? 0 : 1, supplier_id: item.supplier_id },
          section: 'PRICE_OK'
        };
      });
    } else {
      proformaBody = [];
    }

    let estimationBody;

    const ESTIMATION = dataParts.filter(item => item.codfou === null);

    if (ESTIMATION.length) {
      estimationBody = ESTIMATION.map((item, index) => {
        return {
          data: [index, '', item.reference_internal ? item.reference_internal : item.reference_full, null, item.reference_full, null, item.quantity && item.quantity, item.price && item.price],
          info: { is_manufacturer: item.source === 'sup' ? 0 : 1, supplier_id: item.supplier_id },
          section: 'REFERENCE_NOT_FOUND'
        };
      });

    } else {
      estimationBody = [];
    }

    const proformaParams: IUploadFileProformaResponse = {
      filename: '',
      count: dataParts.length,
      delivery_date: calcOffset(props.deliveryDate),
      header: ['Item', 'PART NO.:', 'ORDER NO.:', 'Code fou amf', 'BRAND NO.:', 'Descirption', 'QTY.:', 'E-Reliable Price'],
      rows: proformaBody,
      estimation: estimationBody
    };

    if (props.is_submit_quotation == 1) {
      proformaParams.is_submit_quotation = props.is_submit_quotation;
    }

    if (!proformaBody.length && !estimationBody.length) {
      notify(t('_empty_data_parts'), 'error');
      setLoadingProforma(false);

      return;
    }

    http.proforma.sendproformaAndEstimation(proformaParams).then(
      (res) => {
        notify(t('_order_submitted_successfully'), 'success');

        setResData(res.data);
        setOpenModal(true);

        http.proforma.sendDataEmailEstimation(dataValidateMail);
      }
    ).catch(
      () => {
        notify(t('_something_went_wrong'));
      }
    ).finally(
      () => {
        setLoadingProforma(false);
      }
    );
  };


  const handleOnCloseModal = () => {
    navigate('/espace-automotor/order');
    dispatch(removeImportReferenceData());
    localStorage.removeItem('importReference');
  };

  const tableData: Column<IParts>[] = [
    {
      Header: <Head text={t('_new_reference').toLocaleUpperCase()}></Head>,
      Cell: (newProps) => <div>{newProps.cell.row.original.codfou}{newProps.cell.row.original.reference}</div>,
      accessor: 'reference',
    },
    {
      Header: <Head text={t('_old_reference').toLocaleUpperCase()}></Head>,
      Cell: (newProps) => <div>{newProps.cell.row.original.codfou}{newProps.cell.row.original.replace?.reference}</div>,
      accessor: 'replace',
    },
  ];

  return (
    <React.Fragment>
      <button className={`btn-comand  ${loadingProforma ? 'disabled' : ''}`} type="button" onClick={() => { props.checkRestrictionParts && !props.submitCountryRestriction ? props.checkRestrictionParts() : checkOpenValidateModal(); }}>
        {loadingProforma ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faCartFlatbed} className="icon" />}   {`${t('_convert_to_command')}`}
      </button>

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onRequestClose={handleOnCloseModal}
        childrenComponent={<OrderModal data={resData} handleCloseModal={handleOnCloseModal} />}
      />

      <Modal
        openModal={openModalValidate}
        setOpenModal={setOpenModalValidate}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <div className="modal-proforma-validate">
            <h1>
              <Trans
                i18nKey="_upload_proforma_validate"
                components={{ span: <span className="color-red-h1" /> }}
              /></h1>
            <div className="btn-validate">
              <DefaultButton onClick={() => { setOpenModalValidate(false); setLoadingProforma(false); }} red text={t('_cancel')} />
              {/* <DefaultButton onClick={() => { proforma(); setOpenModalValidate(false); }} text={t('_send')} /> */}
            </div>

          </div>
        }
      />
      <Modal
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <div className="modal-proforma-validate">
            <ReferenceTable columns={tableData} data={props.data} loading={false} />
            <div className="btn-validate">
              <DefaultButton onClick={() => { setOpenConfirmModal(false); }} red text={t('_cancel')} />
              <DefaultButton onClick={() => { proforma(); setOpenConfirmModal(false); }} text={t('_continue_order')} />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

ProformaNew.displayName = 'Proforma';

export interface IValueItems {
  index: number;
  quantity: number;
}

export default ProformaNew;
