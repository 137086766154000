import React, { useEffect, useState } from 'react';
import EspaceView from './Espace.view';
import { IEspaceTabItem } from './Espace.model';
import { useSelector } from 'react-redux';
import { clientItems } from './Espace.map';

import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import { ICatalogs } from 'models/catalog.model';
import { getCatalogData } from 'store/selectors/catalogs.selector';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchCatalogData } from 'store/reducers/catalog/catalogs.actions';
import { RootState } from 'store/store';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';
import useHttp from 'utils/hooks/useHttp';

const Espace: React.FC = () => {
  const [clientTabItems, setClientTabItems] = useState<IEspaceTabItem[]>([]);
  const [supplierTabItems, setSupplierTabItems] = useState<IEspaceTabItem[]>([]);
  const { user } = useSelector((state: RootState) => state.userData);
  const catalogData = useSelector((state:RootState) => state.catalogs.catalogData);
  const dispatch = useAppDispatch();
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const isEnabled = user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);
  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState(false);
  const http = useHttp();

  const fetchData = async () => {
    try {
      const data = catalogData.map((item: ICatalogs): IEspaceTabItem => {
        return {
          id: item.id,
          name: item.name,
          logo: `${getMarketplaceBaseUrl()}${item.images[0]}`, // only getting image for desktop
        };
      });

      setSupplierTabItems(data);

      if (isEnabled != undefined && !isEnabled) {
        setClientTabItems(() => clientItems);
      }
    } catch (error) {
      notify('Internal Server Error');
      setClientTabItems(() => []);
      setSupplierTabItems(() => []);
    }
  };

  useEffect(() => {
    if (!Array.isArray(catalogData) || !catalogData.length) {
      dispatch(fetchCatalogData());
    }

  }, [mounted]);
  
  useEffect(() => {
    setMounted(!!(catalogData.length));
  }, [catalogData]);
  
  useEffect(() => {
    fetchData();
  },[catalogData]);

  useEffect(() => {
    if (isEnabled) {
      setLoading(true);
      http.clients
        .getLogo()
        .then((res) => {
          const data = res.data.map((item: IEspaceTabItem) => {
            return {
              id: item.id,
              name: item.name,
              logo: `${getMarketplaceBaseUrl()}${item.logo}`,
            };
          });

          setClientTabItems(() => data);
        })
        .catch(() => {
          setClientTabItems(() => []);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isEnabled]);

  return (
    <EspaceView
      loading={loading}
      messagesNumber={newMessagesNumber}
      user={user}
      clientTabItems={clientTabItems}
      supplierTabItems={supplierTabItems}
    />
  );
};

Espace.displayName = 'Espace';

export default Espace;
