import React from 'react';

import { Outlet } from 'react-router-dom';

import './EspaceAutomotorMenuTopLayout.scss';
import SliderMenuTop from 'applications/automotor/views/espace-automotor/slider-menu-top/SliderMenuTop';

type Props = {};

const EspaceAutomotorMenuTopLayout: React.FC<Props> = () => {

  return (
    <React.Fragment>
      <div className="automotor-top-menu">
        <div className="automotor-top-menu-container">
          <SliderMenuTop />
        </div>

      </div>
      <Outlet />
    </React.Fragment>
  );
};

EspaceAutomotorMenuTopLayout.displayName = 'EspaceReliableNavbar';

export default EspaceAutomotorMenuTopLayout;
