import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as AutomotorOffer } from 'assets/images/offer-automotor.svg';
import { ReactComponent as PersonalSpace } from 'assets/images/space-personal.svg';
import { ReactComponent as SendCommand } from 'assets/images/send-command.svg';
import { ReactComponent as ProviderIcon } from 'assets/images/service-provider-icon.svg';
import { ReactComponent as DocsIcon } from 'assets/images/documents.svg';
import { ReactComponent as PriceTag } from 'assets/images/price-tag.svg';
import { ReactComponent as Discount } from 'assets/images/discount.svg';
import { ReactComponent as NotificationBell } from 'assets/images/notification-bell.svg';
import { ReactComponent as IndustrialIcon } from 'assets/images/brake-icon.svg';
import { ReactComponent as RestrictionIcon } from 'assets/images/flag-line-icon.svg';
import { ReactComponent as AllowedParts } from 'assets/images/allowed-parts.svg';

import { ReactComponent as PriceManagement } from 'assets/images/price-management.svg';
import { ReactComponent as Client } from 'assets/images/business-team-icon.svg';

import { Pagination, Navigation } from 'swiper';
import SliderMenuItem from './SliderMenuItem/SliderMenuItem';
import { ROLE_COMMERCIAL_DIRECTOR, ROLE_SELLER } from 'utils/constants/roles';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import 'swiper/css/pagination';
import './SliderMenu.scss';


type Props = {
  handleSlideChange?: (slide: IMenuItem) => void
}

const SliderMenu: React.FC<Props> = (props) => {
  const { user } = useSelector((state: RootState) => state.userData);
  const { isMobile } = useDeviceDetect();

  const handleSlideChange = (e) => {
    props.handleSlideChange && props.handleSlideChange(menuItems[e.realIndex]);
  };


  return (
    <div className="slider-menu-container">
      <Swiper
        initialSlide={1}
        slidesPerView={3}
        spaceBetween={1}
        centeredSlides
        slidesPerGroup={1}
        observer
        loop
        onSlideChange={(e) => handleSlideChange(e)}
        navigation={!isMobile}
        pagination={{
          enabled: isMobile,
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
      >
        {
          menuItems.map((item, index) => {
            if (item.role && !item.role.some(role => user?.roles?.includes(role))) { return; }

            return (
              <SwiperSlide key={index}>
                <SliderMenuItem {...item} />
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>
  );
};

SliderMenu.displayName = 'SearchCatalog';

export const menuItems: Array<IMenuItem> = [
  {
    index: 0,
    name: '_offer_automotor',
    title: '_consult_offer_tariff_automotor',
    description: '_discover_offer_tariff_automotor',
    image: AutomotorOffer,
    url: '/espace-automotor/offer'
  },
  {
    index: 1,
    name: '_place_an_order',
    title: '_pass_your_command',
    image: SendCommand,
    url: '/espace-automotor/order'
  },
  {
    index: 2,
    name: '_personal_data_and_orders_reference',
    title: '_my_space_personal',
    image: PersonalSpace,
    url: '/espace-automotor/my-account'
  },
  {
    index: 3,
    name: '_notifications',
    title: '_notifications',
    image: (
      <div>
        <NotificationBell />
      </div>
    ),
    custom: true,
    url: '/espace-automotor/notifications'
  },
  {
    index: 4,
    name: '_proforma_mapping',
    title: '_proforma_mapping',
    role: [ROLE_SELLER],
    image: DocsIcon,
    url: '/espace-automotor/proforma-mapping'
  },
  {
    index: 5,
    name: '_promotions',
    title: '_promotions',
    role: [ROLE_SELLER],
    image: Discount,
    url: '/espace-automotor/promotions'
  },
  {
    index: 6,
    name: '_providers',
    title: '_providers',
    role: [ROLE_SELLER],
    image: ProviderIcon,
    url: '/espace-automotor/providers'
  },
  {
    index: 7,
    name: '_industrial_parts',
    title: '_industrial_parts',
    role: [ROLE_SELLER],
    image: IndustrialIcon,
    url: '/espace-automotor/industrial'
  },
  {
    index: 8,
    name: '_country_restriction',
    title: '_country_restriction',
    role: [ROLE_SELLER],
    image: RestrictionIcon,
    url: '/espace-automotor/country-restriction'
  },
  {
    index: 9,
    name: '_allowed_list',
    title: '_allowed_list',
    role: [ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR],
    image: AllowedParts,
    url: '/espace-automotor/allowed-list'
  },
  {
    index: 10,
    name: '_price',
    title: '_price',
    role: [ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR],
    image: PriceManagement,
    url: '/espace-automotor/price'
  },

  {
    index: 11,
    name: '_seller_area',
    title: '_seller_area',
    role: [ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR],
    image: Client,
    url: '/espace-automotor/area'
  },
];

export type IMenuItem = {
  index: number,
  name: string,
  title: string,
  description?: string,
  image,
  url: string
  role?: string[]
  custom?: boolean
}

export default SliderMenu;
