import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { notify } from 'utils/marketplace';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { IFilterValue } from 'interfaces/tyre';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import i18n from 'i18n';
import Select from 'react-select';
import TextInput from 'components/inputs/TextInput/TextInput';
import 'react-datepicker/dist/react-datepicker.css';
import './CreatePriceManagement.scss';


type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  clients;
  providers;
  loading: boolean;
  countries;
  commercialZones;
};

type IForm = {
  numcli: IFilterValue[] | null;
  codfou: IFilterValue;
  type_form: IFilterValue;
  groups: {
    name: string;
    taux: number;
  }
} & {
  [key: `groups${string}`]: number;
};

const CreatePriceManagement: React.FC<Props> = (props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [groupLoading, setGroupLoading] = useState<boolean>(false);

  const [groups, setGroups] = useState<string[]>([]);

  const validate = () => {
    return Yup.object().shape({
      numcli: Yup.array()
        .required(t('_fild_required'))
        .of(
          Yup.object().shape({
            label: Yup.string().required(t('_fild_required')),
            value: Yup.string().required(t('_fild_required')),
          }),
        ),
      codfou: Yup.object().shape({
        label: Yup.string().required(t('_fild_required')),
        value: Yup.string().required(t('_fild_required')),
      }),
      groups: Yup.array()
        .of(
          Yup.object().shape({}),
        ),

    });
  };

  const {
    getValues,
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<IForm>({ resolver: yupResolver(validate()) });

  useLocaleDatePicker(i18n.language);

  const onSubmit = async () => {
    const values = getValues();

    if (!groups.length) {
      notify(t('_not_have_groups'), 'error', t('_error'));

      return;
    }


    setLoadingSave(true);
    const formData: FormData = new FormData();

    if (watch('type_form.value') === '1') {
      const clients = values.numcli;

      clients?.forEach(({ value }) => {
        formData.append('numcli[]', String(value));
      });
    }

    if (watch('type_form.value') === '2') {
      const ids: Array<string> = [];

      props.clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.label === client.country) {
            ids.push(client.value);
          }
        });
      });


      ids.forEach((id) => {
        formData.append('numcli[]', id);
      });
    }

    if (watch('type_form.value') === '3') {
      const ids: Array<string> = [];

      props.clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.value === client.zone_commercial) {
            ids.push(client.value);
          }
        });
      });

      ids.forEach((id) => {
        formData.append('numcli[]', id);
      });
    }

    formData.append('codfou', String(values.codfou.value));


    Object.entries(values.groups).forEach(([key, value]) => {

      formData.append('groups[]', JSON.stringify(value));
    });


    http.priceMatrix
      .savePriceMatrix(formData)
      .then(() => {
        notify(t('_created_successfully'), 'success', t('_success'));
        props.setOpenModal(false);
        props.setSubmit(true);
        setLoadingSave(false);
      })
      .catch(({ errors }) => {
        notify(errors?.[0]);
        setLoadingSave(false);
      });
  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  useEffect(() => {
    setValue('type_form', { value: '1', label: String(t('_client')) });
  }, []);


  function getCodGroup(provider: string) {
    setGroupLoading(true);
    http.priceMatrix
      .getGroup(provider)
      .then((res) => {
        setGroups(res.data);
        setGroupLoading(false);
      }).catch(() => {
        setGroups([]);
        setGroupLoading(false);
      });

  }

  return (
    <div className="create-price-management-container">
      <div
        className="close-modal"
        onClick={() => {
          props.setOpenModal(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      <div className="title">{t('_create_price_rate')}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-info">
          <div className="row">

            <div className="container">
              <label>{t('_provider')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="codfou"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                          getCodGroup(String(val?.value));
                        }}
                        isLoading={groupLoading}
                        options={props.providers}
                        placeholder={t('_provider')}
                        styles={customStyles(errors.codfou?.label?.type)}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
                <p className="error">{errors.codfou?.label?.type == 'required' ? errors.codfou?.label?.message : ''}</p>
              </div>
            </div>

          </div>
          {groups.length ?
            <div className="groups" >
              <div className="row" >
                <div className="container">
                  <label>{t('_group')}</label>
                </div>
                <div className="container">
                  <label>{t('_coefficient')}</label>
                </div>
              </div>
              {groups.map((item, key) => {
                return (

                  <div className="row" key={item}>
                    <div className="container item_group">
                      <div className="input-item">
                        <TextInput placeholder={t('_group')} {...register(`groups[${key}].name`)} defaultValue={item} readOnly />
                      </div>
                    </div>
                    <div className="container item_group">
                      <div className="input-item ">
                        <TextInput {...register(`groups[${key}].taux`)} placeholder={t('_coefficient')} type="number" min={0} step="any" />
                        <p className="error">
                          {errors?.groups?.[key]?.taux?.type === 'typeError' ? errors.groups[key].taux.message : ''}
                          {errors?.groups?.[key]?.taux?.type === 'min' ? errors.groups[key].taux.message : ''}
                          {errors?.groups?.[key]?.taux?.type === 'required' ? errors.groups[key].taux.message : ''}
                        </p>
                      </div>
                    </div>
                  </div>

                );
              })}

            </div>
            : watch()?.codfou?.value && groups.length == 0 && !groupLoading ? <div className="row"><div className="error_groups"> {t('_not_have_groups')}</div></div> : null
          }


          <div className="row">
            <div className="container">
              <label>{t('_select_by')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="type_form"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                          setValue('numcli', null);
                          resetField('numcli');

                        }}
                        options={[
                          {
                            value: '1',
                            label: String(t('_client')),
                          },
                          {
                            value: '2',
                            label: String(t('_country')),
                          },
                          {
                            value: '3',
                            label: String(t('_zone_commercial')),
                          },
                        ]}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className={`container ${watch()?.type_form?.value === '1' ? '' : 'hide'}`}>
              <label>{t('_client')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="numcli"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);

                          if (val.length == 0) {
                            resetField('numcli');
                          }
                        }}
                        options={props.clients}
                        placeholder={t('_client')}
                        styles={customStyles(errors.numcli?.type)}
                        isSearchable={!isMobile}
                        isMulti
                      />
                    );
                  }}
                />
                <p className="error">{errors.numcli?.type == 'required' ? errors.numcli?.message : ''}</p>
              </div>
            </div>
            <div className={`container ${watch()?.type_form?.value === '2' ? '' : 'hide'} `}>
              <label>{t('_select_by')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="numcli"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {


                          onChange(val);

                          if (val.length == 0) {
                            resetField('numcli');
                          }
                        }}
                        options={props.countries}
                        placeholder={t('_clients_country')}
                        styles={customStyles(errors.numcli?.type)}
                        isSearchable={!isMobile}
                        isMulti
                      />
                    );
                  }}
                />
                <p className="error">{errors.numcli?.type == 'required' ? errors.numcli?.message : ''}</p>
              </div>
            </div>


            <div className={`container ${watch()?.type_form?.value === '3' ? '' : 'hide'} `}>
              <label>{t('_zone_commercial')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="numcli"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);

                          if (val.length == 0) {
                            resetField('numcli');
                          }
                        }}
                        options={props.commercialZones}
                        placeholder={t('_zone_commercial')}
                        styles={customStyles(errors.numcli?.type)}
                        isSearchable={!isMobile}
                        isMulti
                      />
                    );
                  }}
                />
                <p className="error">{errors.numcli?.type == 'required' ? errors.numcli?.message : ''}</p>
              </div>
            </div>
          </div>

        </div>
        <div className="btn-save">
          <button type="submit">
            {loadingSave ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_save')}</div>
          </button>
        </div>
      </form >
    </div >
  );
};

export default CreatePriceManagement;
