import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import AccountDropdown from 'components/dropdowns/AccountDropdown/AccountDropdown';
import CartButton from 'components/buttons/CartButton/CartButton';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getCountryFromLocale, getCountryFromLocalStorage, getLanguageFromLocale, getLanguageFromLocalStorage } from 'utils/locale';
import { countries, DEFAULT_COUNTRY_IMAGE, languages } from 'utils/constants/locales';
import RELIABLE_LOGO from 'assets/images/reliable-logo.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import {
  ROLE_CLIENT_AMERIGO,
  ROLE_CLIENT_AUTOMOTOR,
  ROLE_CLIENT_PLATFORM_ADMIN,
  ROLE_SELLER,
  ROLE_COMMERCIAL_DIRECTOR,
  ROLE_SUPPLIER_AMERIGO,
} from 'utils/constants/roles';
import useHttp from 'utils/hooks/useHttp';
import { IUserPut } from 'utils/hooks/http/user.http';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import HasRoles from 'components/high-order-components/HasRoles/HasRoles';
import useDefaultRouteForRole from 'utils/hooks/useDefaultRouteForRole';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import './EspaceReliableNavbar.scss';
import { Marketplaces } from 'utils/constants/applications';

type Props = {};

const EspaceReliableNavbar: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.userData);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const isDisabled = !user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);
  const isSupplierAmerigo = user?.roles.includes(ROLE_SUPPLIER_AMERIGO);
  const http = useHttp();
  const marketplace = getCurrentMarketplace();
  const [activeBtn, setActiveBtn] = useState<string>(location.pathname.split('/')[1] || 'reliable');
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [selectedCountry, setSelectedCountry] = useState<IDropdownModel>(getCountryFromLocalStorage() ?? countries[0]);

  useEffect(() => {
    updateCartQuantityToolbar();
  }, []);

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/espace-clients') ||
        (location.pathname.includes('/car-parts') && searchParams.get('is_platform_admin') == 'true'):
        setActiveBtn('client');

        break;
      case location.pathname.includes('/universal-catalog') ||
        location.pathname.includes('/catalogs') ||
        (location.pathname.includes('/car-parts') && !searchParams.get('is_platform_admin')) ||
        location.pathname.includes('car-parts/view') ||
        location.pathname == '/catalog' ||
        location.pathname.includes('/technical-documentation') ||
        location.pathname.includes('/error-codes') ||
        location.pathname.includes('/diagnostic') ||
        location.pathname.includes('/news') ||
        (location.pathname.includes('/promotions') && marketplace === 'automotor'):
        setActiveBtn('reliable');

        break;

      case marketplace === 'automotor'
        ? location.pathname.includes('/espace-automotor')
        : location.pathname.includes('/espace-suppliers') || (marketplace === 'amerigo' && location.pathname.includes('/catalog/')):
        setActiveBtn('supplier');

        break;
      default:
        setActiveBtn('');
    }
  }, [location, searchParams.get('is_platform_admin')]);

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }

    const country = getCountryFromLocale(i18n);

    if (country) {
      setSelectedCountry(country);
    }
  }, [i18n.language]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const updateCartQuantityToolbar = () => {
    http.cart.getCartQuantity().then((res) => {
      dispatch(setQuantityToolbar(res.quantity));
    });
  };

  const changeCountry = (item: IDropdownModel) => {
    const language = getLanguageFromLocale(i18n);

    if (!language) {
      return;
    }

    const locale = `${language.id}-${item.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const defaultLinkForRole = useDefaultRouteForRole();


  return (
    <div className="espace-reliable-navbar-container">
      <div className="espace-reliable-navbar">
        <NavLink to={defaultLinkForRole} className="logos">
          <div className="garage-michel">
            {user?.client_logo && <img className="client-logo" src={user?.client_logo + '?' + new Date().getTime()} alt="espace-logo" />}
            <p className="user-name"> {user && user.client_short_name ? <span>{user?.client_short_name}</span> : null}</p>
          </div>
          <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" />
        </NavLink>

        <div className="espace-reliable-tabs">
          <HasRoles roles={[ROLE_SUPPLIER_AMERIGO, ROLE_CLIENT_AMERIGO, ROLE_CLIENT_AUTOMOTOR, ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}>
            <div className={`espace-reliable-tab ${activeBtn == 'reliable' ? 'active' : ''}`}>
              <RoundedButton
                text={<Trans i18nKey="_espace_catalog_button" components={{ span: <span translate="no" /> }} />}
                onClick={() => navigate('universal-catalog')}
              />
              {/* {
                process.env.REACT_APP_MODE === 'preprod' ?
                  <a className={'espace-catalog-button-rounded'} href={`${process.env.REACT_APP_PUBLIC_APP}?language=${language}&country=${country}`} target="_blank" rel="noreferrer">{t('_espace_catalog_button')}</a>
                  : <RoundedButton text={t('_espace_catalog_button')} onClick={() => navigate('universal-catalog')} />
              } */}
            </div>
          </HasRoles>
          <HasRoles roles={[ROLE_CLIENT_AMERIGO, ROLE_CLIENT_AUTOMOTOR, ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}>
            <div className={`espace-reliable-tab ${activeBtn == 'supplier' ? 'active' : ''}`}>
              <RoundedButton
                text={marketplace === Marketplaces.AUTOMOTOR ? t('_espace_automotor') : t('_espace_my_suppliers')}
                onClick={() => navigate(marketplace === Marketplaces.AUTOMOTOR ? 'espace-automotor' : isSupplierAmerigo ? defaultLinkForRole : 'espace-suppliers' )}
              />
            </div>
            <div className={`${'espace-reliable-tab'} ${activeBtn == 'client' ? 'active' : ''} ${isDisabled ? 'disable' : ''}`}>
              <RoundedButton text={t('_espace_my_clients')} onClick={() => (isDisabled ? null : navigate('espace-clients'))} />
            </div>
          </HasRoles>
        </div>

        <div className="user-settings">
          <AccountDropdown />
          <CartButton quantityToolbar={quantityToolbar} />
          <div className="mini-dropdowns">
            <MiniDropdown onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
            <MiniDropdown
              onClick={changeCountry}
              image={selectedCountry ? selectedCountry.image : DEFAULT_COUNTRY_IMAGE}
              data={countries}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EspaceReliableNavbar.displayName = 'EspaceReliableNavbar';

export default EspaceReliableNavbar;
