import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

import './SliderMenuItem.scss';

type Props = {
  index: number,
  name: string,
  title: string,
  description?: string,
  image,
  url: string
  custom?: boolean
}

const SliderMenuItem: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceDetect();

  const renderIcon = (): JSX.Element => {
    if(!props.custom){
      const Icon = props.image;

      return <Icon />;
    }

    return props.image;
  };

  useEffect(() => {
    if (location.pathname === props.url) {
      swiper.slideTo(props.index);
    }
  }, [location.pathname]);
  const swiper = useSwiper();

  return (
    isMobile || isTablet ?
      (
        <div onClick={() => navigate(props.url)} className="automotor-menu-slide-item-mobile">
          <div className="automotor-menu-slide-item-container-mobile">
            <div className="image">
              {renderIcon()}
            </div>
            <div className="name">{t(props.name)}</div>
          </div>
        </div>
      )
      :
      (
        <div onClick={() => navigate(props.url)} className="automotor-menu-slide-item">
          <div className="automotor-menu-slide-item-container">
            <div className="name">{t(props.name)}</div>
            <div className="image">
              {renderIcon()}
            </div>
            <div className="title">{t(props.title)}</div>
            {props.description && <div className="description">
              {t(props.description)}
            </div>}
          </div>
        </div>
      )
  );
};

SliderMenuItem.displayName = 'SearchCatalog';

export default SliderMenuItem;
