import { faBoxOpen, faFileContract, faFileLines, faList, faNewspaper, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';
import TechnicalHelpMobile from 'components/dropdowns/TechnicalHelp/Mobile/TechnicalHelpMobile';
import Modal from 'components/modal/Modal';
import ModalCars from 'components/navbar/ModalCars/ModalCars';
import { SidebarContext, ISidebarContext } from 'context/SidebarContext';
import i18n from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import { RootState } from 'store/store';
import { languages, DEFAULT_COUNTRY_IMAGE, countries } from 'utils/constants/locales';
import { IUserPut } from 'utils/hooks/http/user.http';
import useHttp from 'utils/hooks/useHttp';
import { getLanguageFromLocalStorage, getCountryFromLocalStorage, getCountryFromLocale, getLanguageFromLocale } from 'utils/locale';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { TabCategories } from 'views/Sidebar/Sidebar.model';
import './Sidenav.scss';
import RELIABLE_LOGO from 'assets/images/reliable-logo.svg';
import FOOTER_LOGO from 'assets/images/e-reliable.png';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import HasRoles from 'components/high-order-components/HasRoles/HasRoles';
import {
  ROLE_SUPPLIER_AMERIGO,
  ROLE_CLIENT_AMERIGO,
  ROLE_CLIENT_AUTOMOTOR,
  ROLE_SELLER,
  ROLE_COMMERCIAL_DIRECTOR,
  ROLE_CLIENT_PLATFORM_ADMIN,
} from 'utils/constants/roles';

type Props = {
  isActive: boolean;
  setIsActive: (e: boolean) => void;
};

const Sidenav: React.FC<Props> = (props) => {
  const { setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const { user } = useSelector((state: RootState) => state.userData);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const { carInfo } = useSelector((state: RootState) => state.carSelected);
  const carInfoSelected = localStorage.getItem('carSelected') || carInfo ? JSON.parse(localStorage.getItem('carSelected') as string) : null;
  const http = useHttp();
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [selectedCountry, setSelectedCountry] = useState<IDropdownModel>(getCountryFromLocalStorage() ?? countries[0]);
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeBtn, setActiveBtn] = useState<string>(location.pathname.split('/')[1] || 'reliable');
  const isDisabled = !user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);
  const marketplace = getCurrentMarketplace();

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/espace-clients'):
        setActiveBtn('client');

        break;
      case location.pathname.includes('/universal-catalog') ||
        location.pathname.includes('/catalogs') ||
        location.pathname.includes('/car-parts') ||
        location.pathname.includes('car-parts/view') ||
        location.pathname == '/catalog' ||
        location.pathname.includes('/technical-documentation') ||
        location.pathname.includes('/error-codes') ||
        location.pathname.includes('/diagnostic') ||
        location.pathname.includes('/news'):
        setActiveBtn('reliable');

        break;

      case marketplace === 'automotor' ? location.pathname.includes('/espace-automotor') : location.pathname.includes('/espace-suppliers'):
        setActiveBtn('supplier');

        break;
      default:
        setActiveBtn('');
    }
  }, [location]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const changeCountry = (item: IDropdownModel) => {
    const language = getLanguageFromLocale(i18n);

    if (!language) {
      return;
    }

    const locale = `${language.id}-${item.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }

    const country = getCountryFromLocale(i18n);

    if (country) {
      setSelectedCountry(country);
    }
  }, [i18n.language]);

  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };

  return (
    <div className={`sidenav-espace ${props.isActive ? 'show-sidenav' : 'hidden-sidenav'}`}>
      <div className="header-sidenav-espace">
        <div
          className="logo"
          onClick={() => {
            navigate('/'), props.setIsActive(false);
          }}
        >
          <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" />
        </div>
        <div className="close-btn">
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => {
              props.setIsActive(false), setShow(false);
            }}
            className="close-btn-icon"
          />
        </div>
      </div>
      <div className="tabs-sidenav">
        <div className="sidenav-tabs">
          <HasRoles roles={[ROLE_SUPPLIER_AMERIGO, ROLE_CLIENT_AMERIGO, ROLE_CLIENT_AUTOMOTOR, ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}>
            <div className={`sidenav-tab ${activeBtn == 'reliable' ? 'active' : ''}`}>
              <RoundedButton
                text={<Trans i18nKey="_espace_catalog_button" components={{ span: <span translate="no" /> }} />}
                onClick={() => {
                  navigate('universal-catalog'), props.setIsActive(false);
                }}
              />
            </div>
          </HasRoles>
          <HasRoles roles={[ROLE_CLIENT_AMERIGO, ROLE_CLIENT_AUTOMOTOR, ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}>
            <div className={`sidenav-tab ${activeBtn == 'supplier' ? 'active' : ''}`}>
              <RoundedButton
                text={marketplace === 'automotor' ? t('_espace_automotor') : t('_espace_my_suppliers')}
                onClick={() => {
                  navigate(marketplace === 'automotor' ? 'espace-automotor' : 'espace-suppliers'), props.setIsActive(false);
                }}
              />
            </div>
            <div className={`sidenav-tab ${activeBtn == 'client' ? 'active' : ''} ${isDisabled ? 'disable' : ''}`}>
              <RoundedButton
                text={t('_espace_my_clients')}
                onClick={() => (isDisabled ? null : navigate('espace-clients'), props.setIsActive(false))}
              />
            </div>
          </HasRoles>
        </div>
      </div>
      <div className="setting">
        <button
          type="button"
          className="e-book"
          onClick={() => {
            handleEbooksClick(), props.setIsActive(false), setShow(false);
          }}
        >
          <div className="e-book-button">{t('_catalog_ebooks')}</div>
          <FontAwesomeIcon icon={faFileContract} className="e-book-icon" />
        </button>
        <div className="mini-dropdowns">
          <MiniDropdown onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
          <MiniDropdown onClick={changeCountry} image={selectedCountry ? selectedCountry.image : DEFAULT_COUNTRY_IMAGE} data={countries} />
        </div>
      </div>

      <div className="footer">
        <img src={FOOTER_LOGO} width="121" alt="e-reliable" />
      </div>
      <Modal openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<ModalCars setOpenModal={setOpenModal} link={link} />} />
    </div>
  );
};

Sidenav.displayName = 'Sidenav';

export default Sidenav;
