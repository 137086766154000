import React from 'react';
import { useTranslation } from 'react-i18next';


import { NavLink } from 'react-router-dom';

import IMG from 'assets/images/space-personal.svg';


type Props = {
}

const ClientSelect: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (

    < div className="item-client" >
      <NavLink to="/espace-automotor/area/select">
        <div className="title">
          {t('_client')}
        </div>
        <div className="upload-btn">
          <div className="icon">

            <img src={IMG} alt="" />
          </div>
          <div className="text">
            {t('_select_client')}
          </div>
        </div>
      </NavLink>
    </div >

  );
};

ClientSelect.displayName = 'ClientSelect';

export default ClientSelect;
