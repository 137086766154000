import React, { useCallback, useEffect, useState } from 'react';
import './SubmitEstimation.scss';
import UPLOAD_IMG from 'assets/images/upload.svg';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useDropzone } from 'react-dropzone';
import useHttp from 'utils/hooks/useHttp';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import { notify } from 'utils/marketplace';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeImportReferenceData, setImportReferenceData } from 'store/reducers/importReferenceSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

const SubmitEstimation: React.FC = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const http = useHttp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, [files]);

  const { getRootProps, getInputProps, } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      'text/csv': ['.csv', '.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.csv', '.xls', '.xlsx'],
    },
  });


  useEffect(() => {

    if (files.length) {
      setIsLoading(true);
      const formData: FormData = new FormData();

      formData.append('file', files[0]);
      http.price.import(formData).then((res) => {
        localStorage.removeItem('importReference');
        localStorage.setItem('importReference', JSON.stringify(res.data));
        dispatch(removeImportReferenceData());
        dispatch(setImportReferenceData(res.data));

        setIsLoading(false);
        navigate({
          pathname: '/espace-automotor/offer/import?file=true'
        });

      }

      ).catch((err) => {
        setIsLoading(false);

        const erorr = err.csv[0];

        notify(t('_' + erorr.toLowerCase()), 'default', t('_error'));

      });
    }
  }, [files]);
  const exportDefault = () => {

    setExportLoading(true);
    http.price.exportDefault().then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        { type: res.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `${t('_import_file_example')}.xlsx`;
      element.click();

    }).finally(() => {
      setExportLoading(false);
    });

  };

  return (

    <div className="automotor-submit-estimation">
      <div className="icon">
        {isLoading ? <Spinner class="extra-small" /> : <img src={UPLOAD_IMG} alt="" />}
      </div>

      <div className="button">
        <div {...getRootProps({ className: 'content' })}>
          <input {...getInputProps()} />
          <DefaultButton text={t('_submit_quotation')} />
        </div>
      </div>
      <div className="description">
        <div onClick={exportDefault} className="export_file_example"> {exportLoading ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faFileExport} />} {t('_import_file_example')}</div>
        {t('_desc_submit_quotation')}
      </div>
    </div>
  );
};

SubmitEstimation.displayName = 'SubmitEstimation';

export default SubmitEstimation;
