import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { CartTableItem } from './CartTable.model';
import CartTableView from './CartTable.view';
import CategoriesCell from './Cells/CategoriesCell/CategoriesCell';
import CodeCell from './Cells/CodeCell/CodeCell';

import PriceCell from './Cells/PriceCell/PriceCell';
import ReferenceCell from './Cells/ReferenceCell/ReferenceCell';
import QuantityAvailability from './Cells/QuantityAvailabilityCell/QuantityAvailability';
import ImageCell from './Cells/ImageCell/ImageCell';
import CheckboxCell from './Cells/CheckboxCell/CheckboxCell';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { CartTableContext, ICartTableContext } from '../../../../../context/CartTableContext';
import DeleteCell from 'applications/retailer/views/Cart/CartTable/Cells/DeleteCell/DeleteCell';

type Props = {
  setRowLoading?: (boolean) => void
  rowLoading?: boolean
  handleCheckout?: () => void;
  setCheckedOrderList?: (checkedOrderList: []) => void;
  handleDeleteAll?: () => void;
  data?: CartTableItem[];
  setDataState?
  fetchData?
  totalPartsPrice: number;
  setTotalPartsPrice?: (totalPartsPrice: number) => void
  setLoading?: (loading: boolean) => void;
  loading?: boolean;
};

const CartTable: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getValues, setValue, register } = useForm();

  const { setSelectAll, selectedData, setSelectedData } = useContext(CartTableContext) as ICartTableContext;

  useEffect(() => {

    if (props.data && props.data.length && props.data.length === selectedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedData]);

  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!props.data) {
      return;
    }

    // find the item in the data array by id
    const item = props.data.find((item) => item.id === id);

    if (!item) {
      return;
    }

    // check if item is already in the selectedData array
    const itemInSelectedData = selectedData.find((item) => item.id === id);

    // if item is not in the selectedData array and the checkbox is checked, add it to the array
    if (isChecked && !itemInSelectedData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedData((prevState) => [...prevState, item]);
    } else if (!isChecked && itemInSelectedData) {
      // if item is in the selectedData array and the checkbox is unchecked, remove it from the array
      const selectedDataFiltered = selectedData.filter((item) => item.id !== id);

      setSelectedData(selectedDataFiltered);
    }

    // if all items are selected, check the select all checkbox
    if (selectedData.length === props.data.length) {
      setSelectAll(true);
    }
  };

  const handleSelectAll = (event) => {

    const isChecked = event.target.checked;

    setSelectAll(isChecked);


    if (isChecked) {
      setValue('checkbox-cart', props.data?.map((item) => String(item.id)));
      setSelectedData(props.data ?? []);
    } else {
      setValue('checkbox-cart', props.data && props.data?.length > 1 ? [] : '');
      setSelectedData([]);
    }
  };


  const handleDeleteAll = async () => {

    let items;

    if (Array.isArray(getValues()['checkbox-cart'])) {
      items = getValues()['checkbox-cart']?.map(Number);
    } else {
      items = [Number(getValues()['checkbox-cart'])];
    }

    if (items.length === 0) {
      notify(t('_cart_select_item'), 'info');

      return;
    }

    props.setLoading!(true);


    await http.cart.deleteCartData(items).then(() => {
      notify(t('_cart_deleted'), 'success');
      dispatch(setQuantityToolbar(0));
    }).catch(() => {
      notify('Error');
    }).finally(() => {
      props.setLoading!(false);
    });

    props.fetchData!();
  };

  const handleDelete = useCallback(
    (newProps) => <DeleteCell {...newProps} data={props.data} setData={props.setDataState} rowLoading={props.rowLoading} setRowLoading={props.setRowLoading} setTotalPartsPrice={props.setTotalPartsPrice} />,
    [props.data, props.rowLoading, props.setRowLoading]
  );

  const columns = useMemo(
    (): Column<CartTableItem>[] => [
      {
        id: 'expander',
        Header: '',
        width: 10,
        maxWidth: 10,
        Cell: (props) => {
          return (
            <ExpandButton row={props.row} />
          );
        }
      },
      {
        Header: ' ',
        Cell: (props) => <CheckboxCell {...props} handleOnChange={handleOnChange} register={register}></CheckboxCell>,
        minWidth: 10,
        maxWidth: 25,
      },
      {
        Header: ' ',
        accessor: 'image',
        Cell: ImageCell,
        width: isMobile ? 20 : 80,
      },
      {
        Header: t('_reference'),
        accessor: 'reference',
        Cell: ReferenceCell,
        minWidth: isMobile ? 30 : 250,
      },
      {
        Header: t('_category'),
        accessor: 'subcategory',
        Cell: CategoriesCell,
        minWidth: isMobile ? 20 : 250,
      },
      {
        Header: t('_quantity_availability'),
        accessor: 'quantity_availability',
        Cell: (newProps) => <QuantityAvailability availabilityUnspecified={false} totalPartsPrice={props.totalPartsPrice} setTotalPartsPrice={props.setTotalPartsPrice} {...newProps} refetch={props.fetchData} />,
        maxWidth: 120,
      },
      {
        Header: t('_price'),
        accessor: 'price',
        Cell: (props) => <PriceCell {...props} unspecified={false}></PriceCell>,
        maxWidth: 100,
      },
      {
        Header: t('_barcode'),
        accessor: 'barCode',
        Cell: CodeCell,
        maxWidth: 100,
      },
      {
        Header: t('_delete').toString(),
        Cell: handleDelete,
        width: 40,
      },
    ],
    [props.totalPartsPrice, isMobile, isTablet, isDesktop, selectedData],
  );

  let hiddenColumns: string[] = [];

  if (isTablet) {
    hiddenColumns = ['subcategory'];
  }

  if (isMobile) {
    hiddenColumns = ['subcategory', 'barCode', 'image'];
  }


  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  if (!isMobile && !isTablet) {
    columns.shift();
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data!,
    initialState
  }, useExpanded);

  return (
    <CartTableView
      getTableBodyProps={getTableBodyProps}
      getTableProps={getTableProps}
      headerGroups={headerGroups}
      prepareRow={prepareRow}
      rows={rows}
      hiddenColumns={hiddenColumns}
      loading={props.loading!}
      handleCheckout={props.handleCheckout}
      handleSelectAll={handleSelectAll}
      handleDeleteAll={handleDeleteAll}
      totalPartsPrice={props.totalPartsPrice}
      quantityToolbar={quantityToolbar}
    ></CartTableView>
  );
};

CartTable.displayName = 'CartTable';

export default CartTable;
