import React from 'react';
import './Felicitations.scss';
import { ReactComponent as FelicitationsImage } from 'assets/images/felicitations-image.svg';
import { Trans, useTranslation } from 'react-i18next';
import SendEmailToSeller from 'applications/automotor/components/espace-automotor/send-email-to-seller/SendEmailToSeller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getAutomotorTariff } from 'store/selectors/automotor-tariff.selector';


type Props = {
  setShowCongratulationMessage: React.Dispatch<React.SetStateAction<boolean>>
  showMessageType: string
}
const Felicitations: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const data = useSelector(getAutomotorTariff);


  return (
    <div className="espace-automotor-felicitations">

      <div className="return-btn" onClick={() => { props.setShowCongratulationMessage(false); }}>
        <FontAwesomeIcon icon={faCircleArrowLeft} className="" /> {t('_return')}
      </div>
      <div className="espace-automotor-felicitations-message">

        <div className="felicitations-title">{t('_felicitations_title')}</div>
        <div className="felicitations-image">
          <FelicitationsImage />
        </div>
        <div className="felicitations-text">
          {props.showMessageType === 'MAPPING_DOES_NOT_EXIST' ?
            <Trans i18nKey="_felicitations_message" components={{ br: <br />, span: <span /> }} /> :
            <div dangerouslySetInnerHTML={{ __html: t('_upload_invalid_file_message', { name_seller: data && data[0].name }) }} />}
        </div>
      </div>
      <div className="question-container">
        <SendEmailToSeller />
      </div>
    </div >
  );
};

export default Felicitations;
