import React, { useEffect, useMemo, useState, } from 'react';
import FilterPromotion from './Filter/filter';
import './Promotions.scss';
import CreatePromotions from './ModalCreate/CreatePromotions';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Column, useExpanded, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Head from 'components/tables/Cells/Head/Head';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faFilter, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import EditPromotions from './ModalEdit/EditPromotions';
import Pagination from 'components/pagination/Pagination';
import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import {
  ROLE_SELLER,
  ROLE_COMMERCIAL_DIRECTOR,
  ROLE_SUPPLIER_AMERIGO,
} from 'utils/constants/roles';
import { usePermission } from 'utils/usePermission';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';

type Props = {

};

const Promotions: React.FC<Props> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState<IPromotionDetails[]>([]);
  const [params, setParams] = React.useState({ page: 1 });
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [editId, setEditId] = useState<number | undefined>();
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState<number>(1);
  const permission = usePermission([ROLE_SUPPLIER_AMERIGO]);
  const permissionSeller = usePermission([ROLE_SELLER]);
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [isActive, setIsActive] = useState(false);

  const request = (params) => {

    http.promotions.getPromotion(params).then((res) => {
      setData(res.data.data);
      setPageCount(res.data.total_pages);
    }).finally(() => {
      setLoading(false);
    })
      .catch(() => {
        setLoading(false);
      });

  };


  useEffect(() => {
    setLoading(true);
    request(params);

  }, [params, i18n.language]);


  useEffect(() => {
    if (submit) {
      setLoading(true);
      request(params);
      setSubmit(false);
    }


  }, [submit]);

  const columnsTable = useMemo(
    (): Column<IPromotionDetails>[] => columPromotions(), [i18n.language, data, isMobile, isTablet, isDesktop]
  );

  function columPromotions(): Column<IPromotionDetails>[] {

    return [
      {
        id: 'expander',
        // eslint-disable-next-line react/destructuring-assignment
        Header: '',
        width: 15,
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ row }) => {
          return (
            //Applying the toggle expander props i.e onClick, style and title
            <ExpandButton row={row} />
          );
        }
      },
      {
        Header: <Head text={t('_promotions_title')}></Head>,
        accessor: 'title',


      },
      {
        Header: <Head text={t('_promotions_start_date')}></Head>,
        accessor: 'start_date',
        Cell: (cell) => (<div>  {cell.row.original.start_date ? moment(cell.row.original.start_date).format('DD/MM/YYYY') : ''}</div>),
        width: 60,
        maxWidth: 60,

      },
      {
        Header: <Head text={t('_promotions_end_date')}></Head>,
        accessor: 'end_date',
        Cell: (cell) => (<div>  {cell.row.original.end_date ? moment(cell.row.original.end_date).format('DD/MM/YYYY') : ''}</div>),
        width: 60,
        maxWidth: 60,
      },
      {
        Header: <Head text={t('_status')}></Head>,
        accessor: 'enabled',
        Cell: (cell) => (<div>  {cell.row.original.enabled ? t('_active') : t('_inactive')}</div>),
      },
      {
        Header: <Head text={t('_zone_commercial')}></Head>,
        accessor: 'zone_commercial_code',

      },

      {
        Header: <Head text={t('_description')}></Head>,
        accessor: 'description',

      },
      {
        Header: <Head text={t('_actions')}></Head>,
        accessor: 'id',
        width: isDesktop ? 80 : 110,
        maxWidth: isDesktop ? 80 : 110,
        Cell: (cell) => (<div className="row-aling-right"> <FontAwesomeIcon icon={faEdit} onClick={() => { setEditId(Number(cell.row.original.id)); setOpenModalEdit(true); }} /> <FontAwesomeIcon icon={faTrash} onClick={() => { setDeleteId(Number(cell.row.original.id)); setOpenModalDelete(true); }} /></div>),
      }


    ];

  }


  if (!isMobile && !isTablet) {
    columnsTable.shift();
  }

  const hiddenColumns = isMobile ? ['start_date', 'end_date', 'description'] : [];

  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columnsTable,
    data: data,
    initialState
  }, useExpanded);

  const handlePageClick = (page: number) => {

    setForcePage(page);

    setParams({ ...params, ...{ page } });


  };


  return (
    <div className={`promotions_header ${permission ? 'promotions_amerigo' : ''}   ${permissionSeller ? 'promotions_seller_automotor' : ''} `}>
      {
        permission ?
          <CatalogSupplierNavbar title={`${t('_promotions')} /`} />
          :
          <div className="promotions_title">
            {t('_promotions')}
          </div>
      }
      {
        isMobile ?
          <div className="filter-btns">
            <button className="button-filter-promo" onClick={() => setIsActive(true)}>< FontAwesomeIcon icon={faFilter} /> {t('_filter')}</button >

            <div className="filter-link-btn">
              <button className="add-btn" onClick={() => setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_promotions')} </button>
            </div>
          </div>
          :
          null
      }
      <div className={`promo-filter ${isMobile && !isActive ? 'hidden-filter-promo' : 'show-filter-promo'}`}>
        {
          isMobile ?
            <div className="close-btn">
              <FontAwesomeIcon icon={faXmark} onClick={() => { setIsActive(false); }} className="close-btn-icon" />
            </div> :
            null
        }
        <FilterPromotion setOpenModal={setOpenModal} setParams={setParams} setForcePage={setForcePage} setIsActive={setIsActive} />
      </div>


      <div className="table-wrapper">
        {loading ? <Spinner /> :
          <table {...getTableProps()} >

            <thead className="thead">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                    })} key={index} className="heading"
                    >
                      <div className="table-title">{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {loading && data.length ?

              <tbody>
                <tr>
                  <td colSpan={Object.keys(rows[0].original).length}>
                    <Spinner class="extra-small" /></td>
                </tr>
              </tbody>
              :
              <tbody {...getTableBodyProps} className="body">

                {data.length ?

                  rows.map((row) => {

                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();

                    return (
                      <React.Fragment key={key}>
                        <tr key={key} {...restRowProps} className="cell-row">
                          {row.cells.map((cell) => {
                            const { key, ...restCellProps } = cell.getCellProps();

                            return (
                              <td key={key} {...restCellProps} className="cell">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                        {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                      </React.Fragment>
                    );
                  }) : <tr className="notFond"><td colSpan={5}>{`${t('_no_data_found')}`}</td></tr>}
              </tbody>
            }
          </table>
        }
        {pageCount > 1 && !loading ? < Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage /> : null}
      </div>

      <Modal shouldCloseOnOverlayClick={false} openModal={openModalEdit} setOpenModal={setOpenModalEdit} childrenComponent={<EditPromotions setOpenModal={setOpenModalEdit} setSubmit={setSubmit} editId={editId} />} />
      <Modal shouldCloseOnOverlayClick={false} openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<CreatePromotions setOpenModal={setOpenModal} setSubmit={setSubmit} />} />
      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={<ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={deleteId} link={http.promotions.deletePromotion} />}
      />
    </div >
  );
};

export default Promotions;
