import React from 'react';
import './EspaceAutomotor.scss';
import SliderMenu from './slider-menu/SliderMenu';

const EspaceAutomotor: React.FC = () => {


  return (
    <div className="espace-automotor-container">
      <div className="espace-automotor">
        <SliderMenu />
      </div>
    </div>

  );
};

EspaceAutomotor.displayName = 'EspaceAutomotor';

export default EspaceAutomotor;
