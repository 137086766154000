import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import {  IMenuItem, menuItems } from '../../slider-menu/SliderMenu';

import './SliderMenuTopItem.scss';

type Props = {
  index: number,
  name: string,
  title: string,
  description?: string,
  image,
  url: string
  custom?: boolean,
  menuItems?: IMenuItem[]
}

const SliderMenuTopItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const swiper = useSwiper();
  const navigate = useNavigate();


  // useEffect(()=>{
  //   if(swiper.destroyed){
  //     return;

  //   }


  //   // if(swiper){

  //   // }
  //   const item = menuItems.find((item: IMenuItem) => location.pathname.includes(item.url));

  //   // initialSlide = item?.index;
  //   if(item)
  //   {swiper.slideTo(item.index);}

  // },[location.pathname]);


  const handleClick = () => {
    swiper.slideTo(swiper.clickedIndex);

    if(props.menuItems){
      navigate(props.menuItems[swiper.realIndex].url);
    }
  };

  const renderIcon = (): JSX.Element => {
    if(!props.custom){
      const Icon = props.image;

      return <Icon />;
    }

    return props.image;
  };

  return (
    <div onClick={handleClick} className="automotor-menu-top-slide-item">
      <div className="automotor-menu-top-slide-item-container">
        <div className="image">
          {renderIcon()}
        </div>
        <div className="name">{t(props.name)}</div>
      </div>
    </div>
  );
};

SliderMenuTopItem.displayName = 'SliderMenuTopItem';

export default SliderMenuTopItem;
