import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IAllowedListDetails, IReference, IReferencesResponse } from 'utils/hooks/http/allowed-list.http';
import useHttp from 'utils/hooks/useHttp';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { Column, useTable } from 'react-table';
import Head from 'components/tables/Cells/Head/Head';
import i18n from 'i18n';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import TextInput from 'components/inputs/TextInput/TextInput';
import { Controller, useForm } from 'react-hook-form';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Select from 'react-select';
import { IFilterValue } from 'interfaces/tyre';
import { IOption } from 'interfaces/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import './AllowedListDetails.scss';
import { notify } from 'utils/marketplace';
import TextareaInput from 'components/inputs/TextareaInput/TextareaInput';

type Props = {}

type IForm = {
    numcli: IFilterValue[] | null;
    type_form: IFilterValue;
    reference: string;
    comment: string;
  };

const AllowedListDetails:React.FC<Props> = () => {
  const {t} = useTranslation();
  const http = useHttp();
  const {id} = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const {handleSubmit, getValues, control, register, watch, setValue} = useForm<IForm>();

  const [file, setFile] = useState<string>();

  const [data, setData] = useState<IAllowedListDetails>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [references, setReferences] = useState<IReferencesResponse>();
  const [referencesLoading, setReferencesLoading] = useState(true);

  const [loadingSave, setLoadingSave] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState<number>(1);

  const [clients, setClients] = useState<IOption[]>([]);
  const [countries, setCountries] = useState<IOption[]>([]);
  const [commercialZones, setCommercialZones] = useState<IOption[]>([]);

  function getDataById(){
    setDataLoading(true);
    http.allowedList.getAllowedListById(Number(id))
      .then((res)=>{
        setData(res.data);
        setFile(res.data.file);
      })
      .finally(() => setDataLoading(false));
  }

  function getReferences(params){
    setReferencesLoading(true);
    http.allowedList.getAllowedReferences({...params}, String(id))
      .then((res) => {
        setReferences(res.data);
        setPageCount(res.data.total_pages);
      })
      .finally(() => setReferencesLoading(false));
  }

  function getCountries(){
    http.selectClient.countries().then(res => {
      setCountries(res.data.map((item) => ({ value: item.id, label: item.name })));
    });
  }

  function getClients(){
    http.allowedList.getClients().then(res => {
      setClients(res.data.map((item) => ({ value: String(item.id), label: item.name, country: item.country, zone_commercial: item.zone_commercial})));
    });
  }

  function getCommercialZones(){
    http.selectClient.zoneCommercials().then(res => {
      setCommercialZones(res.data.map((item) => ({ value: item.id, label: item.name })));
    });
  }

  useEffect(()=>{
    getDataById();
    getCountries();
    getClients();
    getCommercialZones();
  },[]);

  useEffect(() => {
    getReferences({ page: Number(searchParams.get('page') || 1)});
  }, [searchParams.get('page')]);

  function handleFilter(){
    getReferences({reference: watch('reference'), page: 1});
  }

  function download(){
    const link = document.createElement('a');

    link.href = file || '#';
    link.setAttribute('download', file?.substring(file?.lastIndexOf('/') + 1, file?.lastIndexOf('.')) || '');

    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function submit(){
    setLoadingSave(true);
    const values = getValues();

    const formData: FormData = new FormData();

    if (watch('type_form.value') === '1') {
      const clients = values.numcli;
  
      clients?.forEach(({ value }) => {
        formData.append('numcli[]', String(value));
      });
    }
 
    if(watch('type_form.value') === '2'){
      const ids: Array<string> = [];
  
      clients?.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.label === client.country) {
            ids.push(client.value);
          }
        });
      });
  
      if (ids.length == 0) {
        notify(t('_error'), 'error', t('_not_have_client_for') + ' ' + values?.numcli?.[0].label);
        setLoadingSave(false);
  
        return;
      }
  
      ids.forEach((id) => {
        formData.append('numcli[]', id);
      });
    }

    if(watch('type_form.value') === '3'){
      const ids: Array<string> = [];
  
      clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.value === client.zone_commercial) {
            ids.push(client.value);
          }
        });
      });
  
      if (ids.length == 0) {
        notify(t('_not_have_client_for') + ' ' + values?.numcli?.[0].label);
        setLoadingSave(false);
  
        return;
      }
  
      ids.forEach((id) => {
        formData.append('numcli[]', id);
      });
    }

    formData.append('comments', values?.comment);

    http.allowedList.editAllowedPart(formData, Number(id))
      .then(() => {
        notify(t('_success'), 'success');
      })
      .finally(() => setLoadingSave(false));
  }

  const columnsTable = useMemo((): Column<IReference>[] => columns(), [i18n.language, references?.data, isMobile, isTablet, isDesktop]);

  function columns(): Column<IReference>[] {
    return [
      {
        Header: <Head text="ID"></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_reference')}></Head>,
        accessor: 'reference',
      },
      {
        Header: <Head text={t('_reference_raw')}></Head>,
        accessor: 'reference_raw',
      },
      {
        Header: <Head text={t('_provider')}></Head>,
        accessor: 'provider',
        Cell: (cell) => <span>{cell.row.original?.provider?.company} ({cell.row.original?.provider?.codfou})</span>
      },
    ];
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columnsTable,
      data: references?.data ? references.data : [],
    },
  );

  const handlePageClick = (page: number) => {
    setForcePage(page);
    setSearchParams({page: String(page)});
  };

  useEffect(() => {
    setValue('type_form', { value: '1', label: String(t('_client')) });

    if(data){
      setValue('numcli', data?.numcli);
    }

  }, [data]);

  return (
    <div className="allowed-list-details">
      <div className="allowed-list-container">
        <div className="allowed-return-button" onClick={() => { navigate('/espace-automotor/allowed-list'); }} >
          <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
          {`${t('_return')}`}
        </div>
        <div className={`allowed-list-container-top ${dataLoading ? 'isloading' : ''}`}>
          {dataLoading ? (
            <Spinner class="data-table-spinner" />
          ) : (
            <div className="allowed-list-data">
              <div className="allowed-list-items">
                <div className="allowed-list-item">
                  <div className="allowed-list-item-label">ID</div>
                  <div className="allowed-list-item-value">#{data?.id}</div>
                </div>
                <div className="allowed-list-item">
                  <div className="allowed-list-item-label">{t('_created_at')}</div>
                  <div className="allowed-list-item-value">{data?.created_at}</div>
                </div>
                <div className="allowed-list-item">
                  <div className="allowed-list-item-label">{t('_created_by')}</div>
                  <div className="allowed-list-item-value">{data?.created_by}</div>
                </div>
                <div className="allowed-list-item">
                  <div className="allowed-list-item-label">{t('_title')}</div>
                  <div className="allowed-list-item-value">{data?.title}</div>
                </div>
                <div className="allowed-list-item">
                  <div className="allowed-list-item-label">{t('_provider')}</div>
                  <div className="allowed-list-item-value">{data?.provider?.company} ({data?.provider?.codfou})</div>
                </div>
                <div className="allowed-list-item">
                  <div className="allowed-list-item-label">{t('_file')}</div>
                  <div className="allowed-list-item-value-file"><FontAwesomeIcon icon={faFileDownload} onClick={download} /></div>
                </div>
              </div>
              <form className="allowed-form" onSubmit={handleSubmit(submit)}>
                <div className="allowed-row">
                  <div className="allowed-container">
                    <label>{t('_select_by')}</label>
                    <div className="input-item">
                      <Controller
                        control={control}
                        name="type_form"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              value={value}
                              className="select-dropdown"
                              onChange={(val) => {
                                onChange(val);
                                setValue('numcli', null);

                                if(watch()?.type_form?.value === '1' && data){
                                  setValue('numcli', data?.numcli);
                                }
                              }}
                              options={[
                                {
                                  value: '1',
                                  label: String(t('_client')),
                                },
                                {
                                  value: '2',
                                  label: String(t('_country')),
                                },
                                {
                                  value: '3',
                                  label: String(t('_zone_commercial')),
                                },
                              ]}
                              isSearchable={!isMobile}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className={`allowed-container ${watch()?.type_form?.value === '2' ? '' : 'hide'}`}>
                    <label>{t('_clients_country')}</label>
                    <div className="input-item">
                      <Controller
                        control={control}
                        name="numcli"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              value={value}
                              className="select-dropdown"
                              onChange={(val) => {
                                onChange(val);
                              }}
                              options={countries}
                              placeholder={t('_clients_country')}
                              isSearchable={!isMobile}
                              isMulti
                            />
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className={`allowed-container ${watch()?.type_form?.value === '1' ? '' : 'hide'}`}>
                    <label>{t('_client')}</label>
                    <div className="input-item">
                      <Controller
                        control={control}
                        name="numcli"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              value={value}
                              className="select-dropdown"
                              onChange={(val) => {
                                onChange(val);
                              }}
                              options={clients}
                              placeholder={t('_client')}
                              isSearchable={!isMobile}
                              isMulti
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className={`allowed-container ${watch()?.type_form?.value === '3' ? '' : 'hide'}`}>
                    <label>{t('_by_zone_commercial')}</label>
                    <div className="input-item">
                      <Controller
                        control={control}
                        name="numcli"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              value={value}
                              className="select-dropdown"
                              onChange={(val) => {
                                onChange(val);
                              }}
                              options={commercialZones}
                              placeholder={t('_zone_commercial')}
                              isSearchable={!isMobile}
                              isMulti
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="allowed-list-comment">
                  <div className="allowed-list-comment-label">{t('_comment')}</div>
                  <div className="comment-input-item">
                    <TextareaInput {...register('comment')} value={data?.comments} placeholder={t('_comment')} noResize rows={8} />
                  </div>
                </div>
                <div className="submit-btn">
                  <DefaultButton text={t('_update')} type="submit" isloading={loadingSave} />
                </div>
              </form>
            </div>
          )}
        </div>
        <div className={`allowed-list-container-bottom ${referencesLoading ? 'isloading' : ''}`}>
          <div className="details-filter">
            <div className="allowed-input-item">
              <TextInput {...register('reference')} placeholder={t('_reference')} />
            </div>
            <div className="allowed-button-item">
              <DefaultButton text={t('_filter')} onClick={handleFilter} />
            </div>
          </div>
          {referencesLoading ? (
            <Spinner class="reference-table-spinner" />
          ) : (
            <div className="table-wrapper">
              <table {...getTableProps()}>
                <thead className="thead">
                  {headerGroups.map((headerGroup, key) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps({
                            style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                          })}
                          key={index}
                          className="heading"
                        >
                          <div className="table-title">{column.render('Header')}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {referencesLoading && references?.data.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={Object.keys(rows[0].original).length}>
                        <Spinner class="extra-small" />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody {...getTableBodyProps} className="body">
                    {references?.data?.length ? (
                      rows.map((row) => {
                        prepareRow(row);
                        const { key, ...restRowProps } = row.getRowProps();

                        return (
                          <React.Fragment key={key}>
                            <tr key={key} {...restRowProps} className="cell-row">
                              {row.cells.map((cell) => {
                                const { key, ...restCellProps } = cell.getCellProps();

                                return (
                                  <td key={key} {...restCellProps} className="cell">
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr className="notFond">
                        <td colSpan={5}>{`${t('_no_data_found')}`}</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {pageCount > 1 && !referencesLoading ? (
                <Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllowedListDetails;