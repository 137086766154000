
import { faCircleArrowLeft, faFileDownload, faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { IDetailsList, ISum } from 'interfaces/remaining';
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column, useExpanded, useTable } from 'react-table';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import './EspaceAutomotorRemaining.scss';
import { columReceived } from './ColumnTable/columnTable';
import i18n from 'i18n';
import Filter from './Filter/filter';
import { isEmpty } from 'lodash';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';


const EspaceAutomotorRemaining: React.FC = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const http = useHttp();
  const [data, setData] = React.useState<IDetailsList[]>([]);
  const [sum, setSum] = React.useState<ISum[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [statusSelected, setStatusSelected] = useState<string>('ALL');
  const { isMobile, isTablet, isDesktop } = useDeviceDetect(768, 1200);
  const [fiter, setFiter] = useState<{}>({});
  const [isActive, setIsActive] = useState(false);
  const { user } = useSelector((state: RootState) => state.userData);
  const [loadingExportMobile, setLoadingExportMobile] = useState<boolean>(false);

  //const status = user?.client_settings.show_financial_not_received ? ['ALL', 'INVOICED', 'TO_INVOICED', 'NOT_RECEIVED'] : ['ALL', 'INVOICED', 'TO_INVOICED'];
  const status = ['ALL', 'INVOICED', 'TO_INVOICED', 'NOT_RECEIVED'];
  const [forcePage, setForcePage] = useState<number>(1);
  const showM3User = user?.is_m3_connected;


  const request = (params) => {


    http.remainingHttp.getList(params).then((res) => {

      setData(res.data.data);
      setSum(res.data.total);
      setPageCount(res.data.total_pages);

    }).finally(() => {


      setLoading(false);
      setLoadingContent(false);

    });
  };


  useEffect(() => {
    const params = {
      page: forcePage,
      per_page: 10,
      status: statusSelected,
    };

    setLoading(true);
    request(params);

  }, []);

  const handlePageClick = (page: number) => {
    setLoadingContent(true);
    setForcePage(page);

    const params = { ...fiter, ...{ page, per_page: 10, status: statusSelected } };

    request(params);

  };


  const searchForStatus = (item: string) => {
    setStatusSelected(item);
    setLoadingContent(true);
    setForcePage(1);
    const params = {
      page: 1,
      per_page: 10,
      status: item,
    };

    request(params);
  };


  // const columnsTable = useMemo(
  //   (): Column<IDetailsList>[] => user?.client_settings.show_financial_not_received ? columReceived() : columNotReceived(), [user,i18n.language ,data]
  // );


  let hiddenColumns = isTablet ? ['reference_dem', 'code_fou', 'date', 'id_folder', 'total_price'] : undefined;

  if (isMobile) {
    hiddenColumns = ['id_folder', 'reference_dem', 'code_fou', 'total_price', 'date', 'quantity_invoiced', 'total_price_invoiced', 'quantity_to_invoiced', 'total_price_to_invoiced', 'quantity_not_received'];
  }

  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};


  const columnsTable = useMemo(
    (): Column<IDetailsList>[] => columReceived(), [user, i18n.language, data, sum, isMobile, isTablet, isDesktop]
  );


  if (!isMobile && !isTablet) {
    columnsTable.shift();
  }


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = useTable({
    columns: columnsTable,
    data: data,
    sum: sum,
    initialState
  }, useExpanded);


  return (
    <div className="form-container-section-account-remaining" >
      <div className="container-section-account-remaining">

        <div className="returnButton" onClick={() => { navigate(-1); }} >
          <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
          {`${t('_return')}`}
        </div>
        <div className="title">{t('_pass_your_command').toLocaleUpperCase()}</div>
        {
          isMobile ?
            <div className="filter-btns">
              <button className="button-filter-remaining" onClick={() => setIsActive(true)}>< FontAwesomeIcon icon={faFilter} /> {t('_filter')}</button >

              <button className="export-btn" onClick={() => setLoadingExportMobile(true)} disabled={loadingExportMobile}> {loadingExportMobile ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faFileDownload} className="icon" />} {t('_export')} </button>
            </div>
            :
            null
        }
        <div className="head-filter">
          {!showM3User ?
            <div className="filter-status">
              <ul className="filter-status-item">
                {
                  status.map((item: string, key: number) => {
                    return (<li className={statusSelected == item ? 'active' : ''} onClick={() => searchForStatus(item)} key={key}>{`${t('_' + item.toLowerCase())}`}</li>);
                  })
                }
              </ul>
            </div> : null
          }

        </div>
        <div className={`remaining-filter ${isMobile && !isActive ? 'hidden-filter-remaining' : 'show-filter-remaining'}`}>
          {
            isMobile ?
              <div className="close-btn">
                <FontAwesomeIcon icon={faXmark} onClick={() => { setIsActive(false); }} className="close-btn-icon" />
              </div> :
              null
          }
          <Filter setSum={setSum} setIsActive={setIsActive} setLoadingExportMobile={setLoadingExportMobile} loadingExportMobile={loadingExportMobile} setForcePage={setForcePage} setFiter={setFiter} setLoading={setLoading} statusSelected={statusSelected} setData={setData} setPageCount={setPageCount} setLoadingContent={setLoadingContent} />
        </div>


        <div className="table-wrapper">
          {loading ? <Spinner /> :
            <table {...getTableProps()} >

              <thead className="thead">
                {headerGroups.map((headerGroup, key) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                    {headerGroup.headers.map((column, index) => (
                      <th {...column.getHeaderProps({
                        style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                      })} key={index} className="heading"
                      >
                        <div className="table-title">{column.render('Header')}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {loadingContent && data.length ?

                <tbody>
                  <tr>
                    <td colSpan={Object.keys(rows[0].original).length}>
                      <Spinner class="extra-small" /></td>
                  </tr>
                </tbody>
                :
                <React.Fragment><tbody {...getTableBodyProps} className="body">

                  {data.length ?

                    rows.map((row) => {

                      prepareRow(row);
                      const { key, ...restRowProps } = row.getRowProps();

                      return (
                        <React.Fragment key={key}>
                          <tr key={key} {...restRowProps} className="body-row-espace">
                            {row.cells.map((cell) => {
                              const { key, ...restCellProps } = cell.getCellProps();

                              return (
                                <td key={key} {...restCellProps} className="cell">
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                          {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                        </React.Fragment>
                      );
                    }) : <tr className="notFond"><td colSpan={14}>{`${t('_no_data_found')}`}</td></tr>}


                </tbody>
                  {!isEmpty(sum[0]?.total_price) ?
                    <tfoot>
                      {footerGroups.map((headerGroup, key) => (
                        <tr {...headerGroup.getFooterGroupProps()} key={key} className="row">
                          {headerGroup.headers.map((column, index) => (
                            <th {...column.getHeaderProps()} key={index} className="heading">
                              <div className="table-title">{column.render('Footer')}</div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </tfoot>
                    : null}

                </React.Fragment>

              }

            </table>

          }

        </div>
        {pageCount > 1 && <Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage />}
      </div>
    </div>
  );
};

EspaceAutomotorRemaining.displayName = 'EspaceAutomotorRemaining';

export default EspaceAutomotorRemaining;
