import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import { RootState } from 'store/store';
import { ROLE_SUPPLIER_AMERIGO } from 'utils/constants/roles';

const useDefaultRouteForRole =  (): string => {

  const userData = useSelector((state: RootState) => state.userData.user);
  const roles = userData?.roles;
  const currentCatalog = useSelector(getCurrentCatalog);


  switch(true){

    case roles && roles.includes(ROLE_SUPPLIER_AMERIGO):

      if(currentCatalog?.id){
        return generatePath('/catalog/:id', {id: currentCatalog?.id});
      }
      
      return '/';
     
    default:
      
      return '/';
      
  }
};

export default useDefaultRouteForRole;
