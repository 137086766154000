import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EspaceSupplier.scss';
import LogoItem from 'components/Item/LogoItem/LogoItem';
import SellersTable from 'components/tables/SellersTable/SellersTable';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Spinner from 'components/spinner/Spinner';
import { IData } from 'models/order';
import useHttp from 'utils/hooks/useHttp';
import { ICatalogs } from 'models/catalog.model';

import { useNavigate } from 'react-router-dom';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import Promotion from 'components/Promotions/Default/Promotion';
import i18n from 'i18n';

type Props = {
  catalogsData: ICatalogs[];
  loadingCatalogs: boolean;
  handleCatalogClick: (id, enabled) => void;
};

const EspaceSupplierView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [promotionItems, setPromotionItems] = useState<IPromotionDetails[]>([]);
  const [loadingPromo, setLoadingPromo,] = useState<boolean>(false);
  const { isMobile, isTablet } = useDeviceDetect();

  function getOrderList(params) {
    setLoading(true);
    http.order
      .getOrders(params)
      .then((res) => setData(res.data.data))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getOrderList({ per_page: 5 });
    getPromotions();
  }, [i18n.language]);

  function getPromotions() {
    setLoadingPromo(true);
    http.promotions.activePromotions().then((res) => {
      setPromotionItems(res.data);
    }).finally(() => { setLoadingPromo(false); })
      .catch(() => {
        setLoadingPromo(false);
      });
  }


  return (
    <div className="espace-supplier-container">
      <div className="espace-supplier-top-container">
        <div className="title">
          {t('_espace')}
          <span>&nbsp;{t('_espace_supplier_title')}</span>
        </div>
        <div className="subtitle">{t('_espace_supplier_subtitle')}</div>

        <div className="items-container">
          {props.loadingCatalogs ? (
            <Spinner class="catalogs-spinner" />
          ) : (
            <div className="items">
              {props.catalogsData.map(({ images, id, enabled }) => {
                return (
                  <LogoItem
                    image={`${getBackendAssetsBasePath()}${images[1]}`}
                    onClick={() => props.handleCatalogClick(id, enabled)}
                    key={id}
                    enabled={enabled}

                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="espace-supplier-main-container container-fluid-large">
        <div className="promotions-slider">
          {

            loadingPromo ? <Spinner class="small" /> :
              promotionItems.length ?
                <Promotion
                  items={promotionItems}
                  slidesPerView={isMobile || isTablet || promotionItems.length == 1 ? 'auto' : 2}
                  navigation={!(isMobile || isTablet) && promotionItems.length != 1}
                  pagination={isMobile || isTablet}
                  autoplay={promotionItems.length != 1}
                  loop={promotionItems.length != 1}
                />
                : null
          }
        </div>
        <div className="order-container">
          <div className="title">{t('_espace_order_title')}</div>
          <div className="order-list-container">
            <SellersTable data={data} loading={loading} />
            <div className="button">
              <DefaultButton text={t('_all_orders')} onClick={() => navigate('/orders')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EspaceSupplierView.displayName = 'EspaceSupplierView';

export default EspaceSupplierView;
