import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import EspaceAutomotorNavbar from './espace-automotor-navbar/EspaceAutomotorNavbar';
import CheckReferenceDisponibility from 'applications/automotor/components/espace-automotor/check-reference-disponibility/CheckReferenceDisponibility';
import SubmitEstimation from 'applications/automotor/components/espace-automotor/submit-estimation/SubmitEstimation';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchAutomotorTariffInfo, fetchAutomotorTariffs } from 'store/reducers/automotor-tariff/automotor-tariff.actions';
import './EspaceAutomotorLayout.scss';
import { useSelector } from 'react-redux';
import { getAutomotorTariff, getTariffs } from 'store/selectors/automotor-tariff.selector';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

const EspaceAutomotorLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const automotorTariff = useSelector(getAutomotorTariff);
  const tariffs = useSelector(getTariffs);
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (!automotorTariff) {
      dispatch(fetchAutomotorTariffInfo());
    }

    if (!tariffs) {
      dispatch(fetchAutomotorTariffs());
    }
  }, [automotorTariff, tariffs]);

  return (
    <div className="espace-automotor-container-wrapper">
      <div className="container">
        <div className="header">
          <EspaceAutomotorNavbar />
        </div>
        <DefaultToastContainer />
        <Outlet />
        <div className="footer">
          {isMobile ? (
            <React.Fragment>
              <CheckReferenceDisponibility />
              <SubmitEstimation />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <SubmitEstimation />
              <CheckReferenceDisponibility />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

EspaceAutomotorLayout.displayName = 'EspaceAutomotorLayout';

export default EspaceAutomotorLayout;
