import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React, { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './CheckoutModal.scss';
import { getCurrentMarketplace, isRole } from '../../../../utils/marketplace';
import { Marketplaces } from '../../../../utils/constants/applications';
import { ROLE_CLIENT_AUTOMOTOR } from '../../../../utils/constants/roles';

type Props = {
  setOpenModal?: (boolean) => void;
  setTabIndex?: (number) => void;
  supplierName?: string;
  orderId: null | number;
  getSuppliers?;
  onCloseModal?: () => void;
};

const CheckoutModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onOrdersClick = () => {
    if (getCurrentMarketplace() === Marketplaces.AMERIGO) {
      navigate('/espace-suppliers/orders');

      return;
    }

    if (!isRole(ROLE_CLIENT_AUTOMOTOR)) {
      navigate('/orders');

      return;
    }

    navigate('/espace-automotor/order/list');
  };

  return (
    <div className="modal-select-checkout">
      <button
        onClick={() => {
          props.setOpenModal!(false);
          props.setTabIndex && props.setTabIndex!(0);
          props.getSuppliers && props.getSuppliers();
          props.onCloseModal && props.onCloseModal();
        }}
        className="close"
      >
        &times;
      </button>
      <div className="modal-dialog">
        <div className="checkout-modal-title">{t('_order_confirmation_checkout')}</div>
        <div className="modal-container">
          <div className="modal-body">
            <div className="modal-success-checkout">
              <FontAwesomeIcon className="modal-success-checkout-icon" icon={faCheckCircle} />
              <div className="modal-success-checkout-text">{t('_thanks_for_order')}</div>
            </div>
            <div className="modal-success-checkout-description">
              <Trans
                i18nKey="_checkout_description"
                values={{
                  supplier_name: props.supplierName ?? '',
                  order_id: props.orderId || '',
                }}
                components={{ em: <em /> }}
              />
            </div>
            <div className="modal-success-checkout-buttons">
              <DefaultButton text={t('_supplier_home')} onClick={() => navigate('/')} />
              <DefaultButton text={t('_my_orders')} onClick={() => onOrdersClick()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CheckoutModal.displayName = 'CheckoutModal';

export default CheckoutModal;
