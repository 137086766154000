import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';
import { IData, IDataResponseHistory } from 'models/order';
import Spinner from 'components/spinner/Spinner';
import Head from 'components/tables/Cells/Head/Head';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import i18n from 'i18n';
import Pagination from 'components/pagination/Pagination';
import DatePicker from 'react-datepicker';
import useHttp from 'utils/hooks/useHttp';
import './OrderTablesHistoryStatus.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCircleRight, } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/inputs/TextInput/TextInput';
import moment from 'moment';
import Select from 'react-select';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { IOption } from 'interfaces/select';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';


type Props = {
  hiddenColumns?
  hideFooter?: boolean;
};


const OrderTablesHistoryStatus: React.FC<Props> = (props) => {
  const { getValues, register, handleSubmit, reset, control } = useForm();
  const [urlParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(urlParams.get('page') ? Number(urlParams.get('page')) : 1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [data, setData] = useState<IData[]>([]);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [clients, setClients] = useState<IOption[]>([]);
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [valueDate, setValueDate] = useState('');
  const [status, setStatus] = useState<IOption[]>([]);


  useOnClickOutside(dateRef, () => setOpenDate(false));


  function getClientOrderHistoryStatus(params) {
    setLoading(true);
    http.order
      .getClientOrderHistoryStatus({ ...params })
      .then((res) => {
        const { data, total_pages } = res.data as IDataResponseHistory;

        setTotalPage(total_pages);
        setData(data);


      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getClientOptions() {
    http.order.getClients().then(res => {
      setClients(res.data);
    });
  }

  function getStatus() {
    http.order.getStatus().then(res => {
      setStatus(res.data);
    });
  }

  useEffect(() => {
    getClientOptions();
    getStatus();
  }, []);

  const handlePageClick = (data: number) => {
    setCurrentPage(data);
  };

  const columns = useMemo(
    (): Column<IData>[] => [
      {
        id: 'expander',
        Header: '',
        width: 15,
        Cell: (row) => {
          return <ExpandButton row={row.row} />;
        },
      },
      {
        Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_price').toLocaleUpperCase()}></Head>,
        accessor: 'total_price',
        Cell: (props) => { return (props.row.values.total_price ? props.row.values.total_price.toFixed(2) : 0); },
      },
      {
        Header: <Head text={t('_client').toLocaleUpperCase()}></Head>,
        accessor: 'client_full_name',
      },
      {
        Header: <Head text={t('_name').toLocaleUpperCase()}></Head>,
        accessor: 'user_full_name',
      },
      {
        Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
        accessor: 'user_email',
      },
      {
        Header: <Head text={t('_proforma_id').toLocaleUpperCase()}></Head>,
        accessor: 'proforma_id',
        Cell: (props) => <span>{props.row.original.m3_order_id ? props.row.original.m3_order_id : props.row.original.proforma_id}</span>
      },
      {
        Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
        accessor: 'status_created_at',
      },
      {
        Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
        accessor: 'new_status',
        Cell: (props) =>
          <div className="status">
            <div className="status_old">
              {(props.row.original?.old_status ? t(props.row.original?.old_status) : '-')}
            </div>
            <div>
              <FontAwesomeIcon icon={faCircleRight} className="icon" />
            </div>
            <div className="status_new">
              {(props.row.original?.new_status ? t(props.row.original?.new_status) : '-')}
            </div>

          </div>
      },


    ],
    [i18n.language, isMobile, isTablet, isDesktop],
  );

  if (!isMobile) {
    columns.shift();
  }

  const hiddenColumns = isMobile ? ['id', 'total_price', 'user_full_name', 'status_created_at', 'user_email', 'proforma_id'] : [];

  const initialState = hiddenColumns ? { hiddenColumns } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: data,
    initialState: initialState

  },
    useExpanded
  );

  function validParams() {
    const data = getValues();

    if (data?.new_status) {
      data.new_status = data.new_status?.value;
    }


    if (data?.old_status) {
      data.old_status = data.old_status?.value;
    }

    if (data?.customer_id) {
      data.customer_id = data.customer_id?.value;
    }

    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');

    if (moment(start_date).isValid()) {
      data.start_date = moment(startDate).format('YYYY-MM-DD');
    }

    if (moment(end_date).isValid()) {
      data.end_date = moment(endDate).format('YYYY-MM-DD');
    }

    const validParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    return validParams;
  }


  const onSubmit = async () => {
    setLoading(true);
    urlParams.delete('page');
    setSearchParams(urlParams);
    setCurrentPage(1);
    getClientOrderHistoryStatus({ ...validParams(), page: 1 });
  };

  useEffect(() => {

    getClientOrderHistoryStatus({ page: urlParams.get('page') ?? 1, ...validParams() });

  }, [urlParams.get('page')]);

  const handleResetFilter = () => {
    reset({ new_status: '', date: '', id: '', client: '', customer_id: '' });
    setCurrentPage(1);
    setSearchParams({ page: '1' });
    setStartDate(null);
    setEndDate(null);
    setValueDate('');
  };

  function onDateChange(dates) {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setValueDate(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`);
    }
    else {

      if (start) {
        setValueDate(`${moment(start).format('DD/MM/YYYY')}`);

      }

      if (end) {
        setValueDate(`${moment(end).format('DD/MM/YYYY')}`);
      }
    }
  }

  function handleRowClick(id: number, key) {
    // console.log(key);

    if (props.hideFooter) {
      if (!(key.includes('cell') && key.includes('expander'))) {
        navigate(`/espace-automotor/area/waiting-confirmation/history-orders/${id}`);
      }
    } else {
      if (!(key.includes('cell') && key.includes('expander'))) {
        navigate(`/waiting-confirmation/history-orders/${id}`);
      }
    }


  }


  return (
    <div className="client-order-table-history">
      <div className="back-btn" onClick={() => { props.hideFooter ? navigate('/espace-automotor/area/waiting-confirmation') : navigate('/waiting-confirmation'); }}>
        <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {t('_return')}
      </div>
      <div className="orders-filter">
        <div className="orders-filter-header">

          <div className="filter-title">{t('_orders_status_history')}</div>

        </div>
        <form onSubmit={handleSubmit(() => onSubmit())}>
          <div className="orders-filter-body">
            <div className="orders-filter-input-item">
              <Controller
                control={control}
                name="new_status"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      className="select-dropdown"
                      onChange={(value) => onChange(value)}
                      options={status}
                      placeholder={t('_status')}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                      isSearchable={!isMobile}
                    />
                  );
                }}
              />
            </div>
            <div className="orders-filter-input-item">
              <Controller
                control={control}
                name="old_status"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      className="select-dropdown"
                      onChange={(value) => onChange(value)}
                      options={status}
                      placeholder={t('_old_status')}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                      isSearchable={!isMobile}
                    />
                  );
                }}
              />
            </div>
            <div className="orders-filter-input-item">
              <div className={`orders-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                <TextInput disabled={openDate} placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
              </div>
              {openDate && (
                <div className="orders-date-picker" ref={dateRef}>
                  <DatePicker
                    {...register('date')}
                    dateFormat="YYYY-MM-DD"
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={isMobile ? 1 : 2}
                    selectsRange
                    inline
                    locale={i18n.language.split('-')[0]}
                  />
                </div>
              )}
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...register('id')} placeholder={t('_by_id')} />
            </div>
            <div className="orders-filter-input-item">
              <Controller
                control={control}
                name="customer_id"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      className="select-dropdown"
                      onChange={(value) => onChange(value)}
                      options={clients}
                      placeholder={t('_client')}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                      isSearchable={!isMobile}
                    />
                  );
                }}
              />
            </div>

            <DefaultButton text={t('_filter')} type="submit" />
            <DefaultButton red text={t('_reset')} onClick={handleResetFilter} />
          </div>
        </form>
      </div>
      <div className="client-order-table-container-history">
        {loading ? <Spinner class="sellers-table-spinner" /> :
          data.length ? (
            <table {...getTableProps()}>

              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row, index) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  if (index === rows.length - 1) {
                    return (
                      <tr key={key} {...restRowProps} className="row" >
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell" onClick={() => handleRowClick(row.original.id, key)}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }

                  return (
                    <React.Fragment key={key}>
                      <tr key={key} {...restRowProps} className="row" >
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell" onClick={() => handleRowClick(row.original.id, key)}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          ) :
            <div className="order-no-data">{t('_no_data')}</div>
        }
      </div>

      <div>
        {totalPage! > 1 && (
          <Pagination pageCount={totalPage!} handlePageClick={handlePageClick} forcePage={currentPage} />
        )}
      </div>

    </div>
  );
};


export default OrderTablesHistoryStatus;
