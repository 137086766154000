
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ILoadingPriceData, ILoadingPriceList } from 'utils/hooks/http/price-list.http';
import useHttp from 'utils/hooks/useHttp';
import './EspaceAutomotorPrice.scss';
import Head from 'components/tables/Cells/Head/Head';
import UploadPriceModal from './UploadPriceModal/UploadPriceModal';
import { Column } from 'react-table';
import DefaultTable from 'components/tables/DefaultTable/DefaultTable';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faHistory, faInfoCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '@ramonak/react-progress-bar';
import FilterPrice from './FilterPrice/FilterPrice';
import UploadPriceModalInfo from './UploadPriceModalInfo/UploadPriceModalInfo';
import { notify } from 'utils/marketplace';
import useTimerMessages from 'utils/hooks/useTimerMessages';
import ExpandButtonDefaut from 'components/tables/DefaultTable/TableMoreInformationDefault/ExpandButton/ExpandButtonDefaut';
import { IOption } from 'interfaces/select';
import i18n from 'i18n';

type Props = {}

const EspaceAutomotorPrice: React.FC<Props> = () => {
  const http = useHttp();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [priceList, setPriceList] = useState<ILoadingPriceList[]>([]);
  const [pagination, setPagination] = useState<ILoadingPriceData>();
  const [openModal, setOpenModal] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [infoIdRow, setInfoIdRow] = useState<number | null>(null);
  const time = useTimerMessages(15000);
  const [loading, setLoading] = useState(false);
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [fiter, setFiter] = useState<{}>({});
  const [forcePage, setForcePage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const [btnFilter, setBtnFilter] = useState(false);
  const [providers, setProviders] = useState<IOption[]>([]);
  const [loadRequest, setLoadRequest] = useState(true);
  const navigate = useNavigate();

  function getLoadingPrice(params) {
    if (priceList.length == 0) {
      setLoading(true);
    }

    http.priceListHttp.getLoadingPrice(params).then(res => {
      setPriceList(res.data.data);
      setPagination(res.data);
      const hasRelevantStatus = res.data.data.some(
        status => status.status === 'IN_PROGRESS' || status.status === 'PENDING'
      );

      setLoadRequest(hasRelevantStatus);

    }).finally(() => {
      setLoading(false);
    });
  }


  function getSupplier() {
    http.allowedList.getProviders().then(res => {
      setProviders(res.data.map((item) => ({ value: item.code, label: `${item.company} (${item.code})` })));
    });
  }


  const handlePageClick = (page) => {
    if (pagination) {
      setLoading(true);
      const paginationCopy = pagination;

      setForcePage(Number(page));
      paginationCopy.page = page;
      setPagination(paginationCopy);
      getLoadingPrice({ ...fiter, page: page });
    }
  };


  useEffect(() => {
    getSupplier();
  }, []);

  useEffect(() => {

    if (loadRequest) {
      getLoadingPrice({ ...fiter, page: forcePage });
    }


  }, [time]);

  useEffect(() => {
    if (btnFilter) {
      setLoading(true);
      getLoadingPrice(fiter);
      setBtnFilter(false);
    }

  }, [btnFilter]);

  function download(file) {
    const link = document.createElement('a');

    link.href = file || '#';
    link.setAttribute('download', file?.substring(file?.lastIndexOf('/') + 1, file?.lastIndexOf('.')) || '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  function infoRow(id: number) {
    setInfoIdRow(id);
    setOpenModalInfo(true);

  }


  function retryStatus(id) {
    http.priceListHttp.priceInfoRetry(id).finally(() => {
      getLoadingPrice({ ...fiter, page: forcePage });
    }).catch(() => {
      notify(t('_error'), 'error', t('_error'));
    });

  }

  function show(id: string, type) {
    document.getElementById(String(id))!.style.display = type;
  }

  function history(id) {
    navigate('/espace-automotor/price/loading-providers/history/' + id);
  }

  const columns = useMemo(
    (): Column<ILoadingPriceList>[] => [
      {
        id: 'expander',
        Header: '',
        width: 10,
        maxWidth: 10,
        Cell: (props) => {
          return (
            <ExpandButtonDefaut row={props.row} />
          );
        }
      },
      {
        Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
        width: 50,
      },
      {
        Header: <Head text={t('_codfou').toLocaleUpperCase()}></Head>,
        accessor: 'codfou',
        width: 50,
      },
      {
        Header: <Head text={t('_file_name').toLocaleUpperCase()}></Head>,
        accessor: 'file_name',
      },
      {
        Header: <Head text={t('_created_at').toLocaleUpperCase()}></Head>,
        accessor: 'created_at',
        width: 100,
      },
      {
        Header: <Head text={t('_started_at').toLocaleUpperCase()}></Head>,
        accessor: 'started_at',
        width: 100,
      },
      {
        Header: <Head text={t('_finished_at').toLocaleUpperCase()}></Head>,
        accessor: 'finished_at',
        width: 100,
      },
      {
        Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
        accessor: 'status',
        width: 100,
        Cell: (props) => <div className={`status ${props.row.original?.status == 'FAILED' ? 'failed' : ''} ${props.row.original?.status == 'FINISHED' ? 'finished' : ''} ${props.row.original?.status == 'IN_PROGRESS' ? 'inprogress' : ''}${props.row.original?.status == 'PENDING' ? 'pending' : ''} `}>{t('_' + props.row.original?.status)}</div>
      },
      {
        Header: <Head text={t('_PROGRESS').toLocaleUpperCase()}></Head>,
        accessor: 'progress',
        width: 200,
        Cell: (props) => <div ><ProgressBar completed={props.row.original?.progress ?? 0} maxCompleted={100} bgColor="#355099" labelSize="13px" height="20px" borderRadius="5px"></ProgressBar></div>
      },

      {
        Header: t('_action').toString().toLocaleUpperCase(),

        Cell: (cell) =>
          <div className="action">
            <div className="action">
              <span>
                <FontAwesomeIcon icon={faFileDownload}
                  className="faFileDownload"
                  onClick={() => download(cell.row.original.file)}
                  onMouseEnter={() =>
                    show(String('download' + cell.row.original.id), 'block')
                  }
                  onMouseLeave={() =>
                    setTimeout(() => {
                      show(String('download' + cell.row.original.id), 'none');
                    }, 100)
                  }
                />
                <div
                  id={String('download' + cell.row.original.id)}
                  className="tooltip_lable"
                >
                  {t('_download')}
                </div>
              </span>
            </div>
            <div className="action">
              <span>
                <FontAwesomeIcon icon={faInfoCircle}
                  className="faFileDownload"
                  color="#ffc107"
                  onClick={() => infoRow(cell.row.original.id)}
                  onMouseEnter={() =>
                    show(String('info' + cell.row.original.id), 'block')
                  }
                  onMouseLeave={() =>
                    setTimeout(() => {
                      show(String('info' + cell.row.original.id), 'none');
                    }, 100)
                  }
                />
                <div
                  id={String('info' + cell.row.original.id)}
                  className="tooltip_lable"
                >
                  {t('_info')}
                </div>
              </span>
            </div>
            <div className="action">
              <span>
                <FontAwesomeIcon icon={faHistory}
                  className="faFileDownload"
                  onClick={() => history(cell.row.original.id)}
                  onMouseEnter={() =>
                    show(String('history' + cell.row.original.id), 'block')
                  }
                  onMouseLeave={() =>
                    setTimeout(() => {
                      show(String('history' + cell.row.original.id), 'none');
                    }, 100)
                  }
                />
                <div
                  id={String('history' + cell.row.original.id)}
                  className="tooltip_lable"
                >
                  {t('_history')}
                </div>
              </span>
            </div>

            <div className="action">
              <span>
                <FontAwesomeIcon icon={faRefresh}
                  className={`faFileDownload  ${cell.row.original.status != 'FAILED' ? 'disabled' : ''}`}
                  color="#ca312a"
                  onClick={() => retryStatus(cell.row.original.id)}
                  onMouseEnter={() =>
                    show(String('retry' + cell.row.original.id), 'block')
                  }
                  onMouseLeave={() =>
                    setTimeout(() => {
                      show(String('retry' + cell.row.original.id), 'none');
                    }, 100)
                  }
                />
                <div
                  id={String('retry' + cell.row.original.id)}
                  className="tooltip_lable"
                >
                  {t('_retry')}
                </div>
              </span>


            </div >
          </div>

        ,
        accessor: 'file',
        width: 60,
      },
      {
        Header: <Head text={t('_logs').toLocaleUpperCase()}></Head>,
        accessor: 'logs',
        width: 100,
        Cell: (props) => <div >{props.row.original.logs ? props.row.original.logs : '-'}</div>
      },
    ],
    [isMobile, isTablet, isDesktop, priceList, i18n.language],
  );

  let hidden: string[] = [];

  if (isTablet) {
    hidden = ['created_at', 'created_at', 'file_name', 'logs'];
  }

  if (isMobile) {
    hidden = ['id', 'created_at', 'file_name', 'progress', 'started_at', 'finished_at', 'logs'];
  }

  if (isDesktop) {
    hidden = ['file_name', 'created_at', 'logs'];
  }

  if (!isMobile && !isTablet && !isDesktop) {
    columns.shift();
  }

  const hiddenColumns = { hiddenColumns: hidden };

  return (
    <div className="espace-automotor-price">
      <div className="price-list-container">
        <div className="price-list-header">

          <div className="price-list-title">{t('_loading_providers_price')}</div>

        </div>
        <FilterPrice setOpenModal={setOpenModal} setForcePage={setForcePage} setFiter={setFiter} setBtnFilter={setBtnFilter} />

        <div className="price-items">
          {loading ? <Spinner class="small prices-spinner" /> :
            <React.Fragment>
              <DefaultTable columns={columns} data={priceList} loading={false} hiddenColumns={hiddenColumns} />

              {(priceList.length && pagination && pagination?.total_pages > 1) ? <Pagination pageCount={pagination.total_pages} handlePageClick={handlePageClick!} forcePage={pagination.page!} /> : null}
            </React.Fragment>

          }
        </div>

        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shouldCloseOnOverlayClick={false}
          childrenComponent={
            <UploadPriceModal
              providers={providers}
              getLoadingPrice={getLoadingPrice}
              setOpenModal={setOpenModal}
            />
          }
        />

        <Modal
          openModal={openModalInfo}
          setOpenModal={setOpenModalInfo}
          shouldCloseOnOverlayClick={false}
          childrenComponent={
            <UploadPriceModalInfo setOpenModalInfo={setOpenModalInfo} id={infoIdRow} />
          }
        />
      </div>


    </div>
  );
};

export default EspaceAutomotorPrice;