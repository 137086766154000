import React from 'react';
import { Outlet } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import Footer from '../../../../components/footer/Footer';
import '../../../DefaultCss.scss';


const MainLayoutNoHeader: React.FC = () => {
  const { isMobile, isTablet } = useDeviceDetect();


  return (
    <div className={`app-automotor no-header container-wrapper ${isMobile ? 'app-layout-mobile' : isTablet ? 'app-layout-tablet' : 'app-layout-desktop'}`}>
      <div className="container">
        <div className="body">
          <Outlet />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

MainLayoutNoHeader.displayName = 'MainLayoutNoHeader';

export default MainLayoutNoHeader;
