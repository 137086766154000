import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IData, IFilterForm } from 'interfaces/selectClient';
import React, { PropsWithChildren } from 'react';
import { Control, FieldValues, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

import ClientOrderList from './ClientOrderList/ClientOrderList';
import { usePermission } from 'utils/usePermission';
import { ROLE_COMMERCIAL_DIRECTOR, ROLE_SELLER } from 'utils/constants/roles';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import ClientOfferList from './ClientOfferList/ClientOfferList';
import ClientList from './ClientList/ClientList';
import './SelectClient.scss';
import Tabs from './Tabs/Tabs';
import Tab from './Tabs/Tab';
import { useLocation } from 'react-router-dom';
import OrderTablesHistoryStatus from './ClientOrderList/OrderTablesHistoryStatus/OrderTablesHistoryStatus';
import OrderInfoDetails from './ClientOrderList/OrderInfoDetails/OrderInfoDetails';

interface IOption {
  label: string;
  value: string;
}

type Props = {
  handlePageClick: (page: number) => void;
  infoClients: IData[] | undefined;
  curentPage: number;
  totalPage: number;
  loading: boolean;
  optionsZoneCommercials: IOption[];
  optionsCountries: IOption[];
  onSubmit: () => void;
  handleSubmit: UseFormHandleSubmit<IFilterForm>;
  register: UseFormRegister<IFilterForm>;
  resetFilter: () => void;
  control: Control<FieldValues>;
  valueCountries: IOption | null;
  setValueCountries: (e: IOption | null) => void;
  valueZoneCommercials: IOption | null;
  setValueZoneCommercials: (e: IOption | null) => void;
  handleSelectClient: (e: number) => void;
  loadingSaveClient: boolean;
  clientIdForSpinner: number;
  logout: () => void;
  slider?: boolean;
};

const SelectClientView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();
  const isCommercial = usePermission([ROLE_COMMERCIAL_DIRECTOR]);
  const isSeller = usePermission([ROLE_SELLER]);
  const location = useLocation();

  function renderContent() {
    console.log(props.slider);

    if (props.slider) {
      switch (true) {

        case location.pathname.includes('espace-automotor/area/waiting-offers'): {
          return (
            <React.Fragment>
              <div className="title-heder">{t('_waiting_offers')}</div>
              <ClientOfferList />
            </React.Fragment>
          );
        }
        case location.pathname.includes('espace-automotor/area/waiting-confirmation'): {
          return (
            <React.Fragment>
              <div className="title-heder">{t('_waiting_confirmation')}</div>
              {location.pathname.includes('history-orders') ?

                location.pathname.includes('history-orders/') ?
                  <OrderInfoDetails hideFooter={props.slider} />
                  : < OrderTablesHistoryStatus hideFooter={props.slider} />

                :
                <ClientOrderList hideFooter={props.slider} />}

            </React.Fragment>
          );
        }
        default: {

          return (
            <React.Fragment>
              <div className="title-heder">{t('_select_client')}</div>
              <ClientList
                curentPage={props.curentPage}
                totalPage={props.totalPage}
                infoClients={props.infoClients}
                handlePageClick={props.handlePageClick}
                loading={props.loading}
                optionsZoneCommercials={props.optionsZoneCommercials}
                optionsCountries={props.optionsCountries}
                handleSubmit={props.handleSubmit}
                onSubmit={props.onSubmit}
                register={props.register}
                resetFilter={props.resetFilter}
                control={props.control}
                valueCountries={props.valueCountries}
                setValueCountries={props.setValueCountries}
                valueZoneCommercials={props.valueZoneCommercials}
                setValueZoneCommercials={props.setValueZoneCommercials}
                handleSelectClient={props.handleSelectClient}
                loadingSaveClient={props.loadingSaveClient}
                clientIdForSpinner={props.clientIdForSpinner}
                logout={props.logout}
                hideFooter={props.slider}
              />
            </React.Fragment>
          );
        }
      }
    } else {
      switch (true) {
        case isCommercial: {
          return <Tabs >

            <Tab title={t('_waiting_confirmation')} activeTab="/waiting-confirmation">
              {location.pathname.includes('history-orders') ?

                location.pathname.includes('history-orders/') ?
                  <OrderInfoDetails />
                  : < OrderTablesHistoryStatus />

                :
                <ClientOrderList />}
            </Tab>
            <Tab title={t('_waiting_offers')} activeTab="/waiting-offers">
              <ClientOfferList />
            </Tab>
            <Tab title={t('_clients')} activeTab="/select-client">
              <ClientList
                curentPage={props.curentPage}
                totalPage={props.totalPage}
                infoClients={props.infoClients}
                handlePageClick={props.handlePageClick}
                loading={props.loading}
                optionsZoneCommercials={props.optionsZoneCommercials}
                optionsCountries={props.optionsCountries}
                handleSubmit={props.handleSubmit}
                onSubmit={props.onSubmit}
                register={props.register}
                resetFilter={props.resetFilter}
                control={props.control}
                valueCountries={props.valueCountries}
                setValueCountries={props.setValueCountries}
                valueZoneCommercials={props.valueZoneCommercials}
                setValueZoneCommercials={props.setValueZoneCommercials}
                handleSelectClient={props.handleSelectClient}
                loadingSaveClient={props.loadingSaveClient}
                clientIdForSpinner={props.clientIdForSpinner}
                logout={props.logout}
              />
            </Tab>
          </Tabs>;
        }
        case isSeller: {
          return <Tabs>
            <Tab title={t('_clients')} activeTab="/select-client">
              <ClientList
                curentPage={props.curentPage}
                totalPage={props.totalPage}
                infoClients={props.infoClients}
                handlePageClick={props.handlePageClick}
                loading={props.loading}
                optionsZoneCommercials={props.optionsZoneCommercials}
                optionsCountries={props.optionsCountries}
                handleSubmit={props.handleSubmit}
                onSubmit={props.onSubmit}
                register={props.register}
                resetFilter={props.resetFilter}
                control={props.control}
                valueCountries={props.valueCountries}
                setValueCountries={props.setValueCountries}
                valueZoneCommercials={props.valueZoneCommercials}
                setValueZoneCommercials={props.setValueZoneCommercials}
                handleSelectClient={props.handleSelectClient}
                loadingSaveClient={props.loadingSaveClient}
                clientIdForSpinner={props.clientIdForSpinner}
                logout={props.logout}
              />
            </Tab>
            <Tab title={t('_waiting_offers')} activeTab="/waiting-offers">
              <ClientOfferList seller />
            </Tab>
          </Tabs>;
        }
        default: {
          return (
            <ClientList
              curentPage={props.curentPage}
              totalPage={props.totalPage}
              infoClients={props.infoClients}
              handlePageClick={props.handlePageClick}
              loading={props.loading}
              optionsZoneCommercials={props.optionsZoneCommercials}
              optionsCountries={props.optionsCountries}
              handleSubmit={props.handleSubmit}
              onSubmit={props.onSubmit}
              register={props.register}
              resetFilter={props.resetFilter}
              control={props.control}
              valueCountries={props.valueCountries}
              setValueCountries={props.setValueCountries}
              valueZoneCommercials={props.valueZoneCommercials}
              setValueZoneCommercials={props.setValueZoneCommercials}
              handleSelectClient={props.handleSelectClient}
              loadingSaveClient={props.loadingSaveClient}
              clientIdForSpinner={props.clientIdForSpinner}
              logout={props.logout}
            />);
        }
      }
    }

  }

  return (
    <div className={`app-automotor seller-group select-client-container ${isMobile ? 'app-layout-mobile' : isTablet ? 'app-layout-tablet' : 'app-layout-desktop'}`}>
      <DefaultToastContainer />
      <div className="container-items">

        <div className="container-top-header">
          {!props.slider ?
            <React.Fragment>
              <div className="header-body">
                <div className="header-icon">
                  <FontAwesomeIcon icon={faWarning} className="icon" />
                </div>
                {t('_select_client_to_continue')}
              </div><div className="header-btn">
                <button className="header-btn-logout" type="button" onClick={props.logout}>
                  {t('_logout')}
                </button>
              </div>
            </React.Fragment>
            : null
          }
        </div>
        {renderContent()}
      </div>
    </div >
  );
};

SelectClientView.displayName = 'SelectClientView';

export default SelectClientView;
