
import Spinner from 'components/spinner/Spinner';
import { IClinetInfo, IEXWORKGROUP } from 'interfaces/financial';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useHttp from 'utils/hooks/useHttp';


type Props = {

}

const ClientInfo: React.FC<Props> = () => {

  const http = useHttp();
  const { t } = useTranslation();
  const [data, setData] = useState<IClinetInfo>();
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const [currencyClient, setCurrencyClient] = useState<string>('');

  useEffect(() => {
    http.financialHttp.getInfoClient().then((res) => {

      setData(res.data);

      if (res.data.currency == 'EUR') {

        setCurrencyClient('€');
      }

      if (res.data.currency == 'USD') {
        setCurrencyClient('$');
      }

      if (res.data.currency == 'GBP') {
        setCurrencyClient('£');
      }

    }).finally(() => {
      setLoadingClient(false);
    }).catch(() => {
      setLoadingClient(false);
    });
  }, []);

  return (
    <div className="client-info">
      <div className="client-title">
        {t('_client_info')}
      </div>
      {loadingClient ? <Spinner class="extra-small" />
        : isEmpty(data) ? (
          <div className="not-data-client">
            {t('_no_data')}
          </div>
        ) : (
          <div className="financial-info-client">
            <div className="row-client">
              <span className="label-client">{t('_financial_pay')}</span>
              <div className="label-number"> {`  ${data?.pay ? Number(data?.pay).toFixed(2) : '0'} ${currencyClient}`}</div>
            </div>

            <div className="row-client">
              <span className="label-client">{t('_financial_currency')}</span>
              <div className="label-number">
                <div className="label-number-year">
                  {

                    data?.ex_work_group ? data.ex_work_group.map((item: IEXWORKGROUP, index) => {
                      return (
                        <div className="label-year" key={index}> <div style={{ fontWeight: 'bold' }}> {item?.date} </div> {`  ${item?.ex_work ? Number(item?.ex_work).toFixed(2) : '0'}   ${currencyClient}`} </div>
                      );
                    }) : null
                  }

                </div>

              </div>
            </div>

          </div>
        )}

    </div>
  );
};

ClientInfo.displayName = 'ClientInfo';

export default ClientInfo;
