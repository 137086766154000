import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import './CartEspaceAutomotor.scss';
import { notify } from 'utils/marketplace';
import useHttp from 'utils/hooks/useHttp';

import { GetCartResponseEspace } from 'models/cart.model';
import Spinner from 'components/spinner/Spinner';
import { useDispatch } from 'react-redux';
import { setQuantityEspaceAutomotor } from 'store/slices/cartEspaceAutomotor.slice';
import CartTableEspace from './CartTableEspace/CartTableEspace';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IProformaResponse } from 'utils/hooks/http/proforma.http';
import Modal from 'components/modal/Modal';
import OrderModal from 'applications/automotor/components/OrderModal/OrderModal';
import { useNavigate } from 'react-router-dom';

type Props = {};

const CartEspaceAutomotor: React.FC<Props> = () => {
  const { t } = useTranslation();
  const http = useHttp();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<GetCartResponseEspace[]>([]);
  const [checkoutData, setCheckoutData] = useState<IProformaResponse>();
  const dispatch = useDispatch();
  const [totalPartsPrice, setTotalPartsPrice] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoadingData(true);
      const response = await http.cartEspace.getCartDataEspace().finally(() => {
        setLoadingData(false);
        setLoading(false);
      });

      setData(response.data.data);
      setTotalPartsPrice(Number(response.data.total_price));
      dispatch(setQuantityEspaceAutomotor(response.data.total_quantity));
    } catch (error) {
      setLoadingData(false);
      notify('Error');
      setData!(() => []);
    }
  };

  function handleCheckout() {
    setLoading(true);

    http.cartEspace
      .cartDataEspaceCheckout()
      .then((res) => {
        setData([]);
        setTotalPartsPrice(0);
        dispatch(setQuantityEspaceAutomotor(0));
        setCheckoutData(res.data);
        setOpenModal(true);
      })
      .catch(() => {
        notify('Error');
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });

  }

  const setValueItems = async (element) => {
    setLoading(true);
    const response = await http.cartEspace.patchCartDataEspace(element.id, { quantity: element.quantity }).finally(() => {
      setLoading(false);
    });

    const obj = [...JSON.parse(JSON.stringify(data))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map(item => {
        if (item.id === element.id) {
          item.quantity = element.quantity;
          item.total_price = element.quantity * item.price;
        }

        return item;
      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      setData(newData);


    }

    setTotalPartsPrice(Number(response.data.total_price));
    dispatch(setQuantityEspaceAutomotor(response.data.total_quantity));
  };


  const removeRow = async (id) => {

    setLoading(true);
    const response = await http.cartEspace.deleteCartEspace(id).finally(() => {
      setLoading(false);
    });

    setData(response.data.data);
    setTotalPartsPrice(Number(response.data.total_price));
    dispatch(setQuantityEspaceAutomotor(response.data.total_quantity));
  };

  const validateParts = () => {
    let validateProforma = true;
    const errornotValid: Array<string> = [];
    const obj = [...JSON.parse(JSON.stringify(data))];


    obj.map((items) => {
      let total_provider_franco = 0;
      let total_provider = 0;

      items.parts.map((i) => {
        if (Number(i.quantity) >= i.min_quantity && Number(i.quantity) % i.uv == 0) {
          total_provider += 1;
          total_provider_franco += Number(i.total_price);
        }
      });

      if (total_provider_franco < Number(items.franco) && total_provider != 0 && items.name != 'NOT_SUPPLIED') {
        validateProforma = false;
        errornotValid.push(t('_min_franco'));
      }

      items.parts.map((item) => {
        if (Number(item.quantity) < item.min_quantity && items.name != 'NOT_SUPPLIED') {
          validateProforma = false;
          errornotValid.push(t('_min_quantity'));
        }

        if (Number(item.quantity) % item.uv != 0 && items.name != 'NOT_SUPPLIED') {
          validateProforma = false;
          errornotValid.push(t('_validate_uv'));
        }
      });

    });

    return validateProforma;
  };

  const handleOnCloseModal = () => {
    navigate('/espace-automotor/order');
  };

  return (
    <div className="cart-view-container-espace">
      <div className={`cart-view-body-espace ${loadingData ? 'loading' : null}`}>

        {loadingData ? (
          <Spinner class="small" />
        ) : data && data.length > 0 ? (
          <div className="cart-view">

            <div className="cart">
              <div className="cart-title">
                {t('_your_shopping_carts')}
              </div>

              <div className="checkout-button">
                <DefaultButton disabled={!validateParts()} text={t('_confirm_order')} onClick={handleCheckout} red />
              </div>
            </div>

            < CartTableEspace data={data} total={totalPartsPrice} setValueItems={setValueItems} removeRow={removeRow} loading={loading} />
          </div>
        ) : (
          <div className="empty_cart">
            <div className="empty_cart_image">
              <img src={require('assets/images/empty_cart.png')} alt="empty_cart" />
            </div>
            <div className="empty_cart_title">{t('_your_shopping_carts_empty')}</div>
          </div>
        )}
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onRequestClose={handleOnCloseModal}
        childrenComponent={<OrderModal data={checkoutData} handleCloseModal={handleOnCloseModal} />}
      />
    </div>
  );
};

export default CartEspaceAutomotor;
