import React, { PropsWithChildren, useEffect, useState } from 'react';
import EspaceNavbar from '../../components/espace/navbar/espace-navbar/EspaceNavbar';
import EspaceReliableCatalog from '../../components/espace/espace-reliable-catalog/EspaceReliableCatalog';
import EspaceTab from '../../components/espace/espace-tab/EspaceTab';
import { IEspaceTabItem } from './Espace.model';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../../../models/IUser';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';
import useHttp from 'utils/hooks/useHttp';
import './Espace.scss';
import { setQuantityEspaceAutomotor } from 'store/slices/cartEspaceAutomotor.slice';
import { useDispatch } from 'react-redux';
import Promotion from 'components/Promotions/Default/Promotion';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  clientTabItems: IEspaceTabItem[];
  supplierTabItems: IEspaceTabItem[];
  user: IUser | null;
  messagesNumber: number;
  loading?: boolean;

};

const EspaceView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const http = useHttp();
  const [countMessagesSeller, setCountMessagesSeller] = useState(0);
  const [countMessages, setCountMessages] = useState(0);
  const isEnabled = props.user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);
  const dispatch = useDispatch();
  const [promotionItems, setPromotionItems] = useState<IPromotionDetails[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    getPromotions();
    http.messaging.countNewMessagesSeller().then((res) => {
      setCountMessagesSeller(res.data);
    });
    http.messaging.countNewMessages({}).then((res) => {
      setCountMessages(res.data);
    });
    http.cartEspace.getCountCartEspace().then((res) => {
      dispatch(setQuantityEspaceAutomotor(res.data.quantity));
    });

  }, []);

  function getPromotions() {

    http.promotions.activePromotions().then((res) => {
      setPromotionItems(res.data);
    });
  }

  return (
    <div className="automotor-espace espace-container">
      <EspaceNavbar />
      <EspaceReliableCatalog />
      <div className="tabs">
        <div className="tab">
          <div className={`promotions-espace ${promotionItems.length == 0 ? '' : 'promotions-espace-show'}`}>
            {promotionItems.length ?
              <Promotion
                items={promotionItems}
                slidesPerView="auto"
                navigation={!(isMobile || isTablet) && promotionItems.length != 1}
                pagination={isMobile || isTablet}
                autoplay={promotionItems.length != 1}
                loop={promotionItems.length != 1}
              />
              : null
            }
          </div>
        </div>
      </div>
      <div className="tabs">
        <div className="tab">
          <EspaceTab
            messagesNumber={countMessagesSeller}
            warningsNumber={0}
            type="_automotor_portal"
            onClick={() => null}
            description={t('_espace_supplier_description')}
            buttonText={t('_espace_automotor_button')}
            buttonLink="espace-automotor"
            items={props.supplierTabItems}
            disableCatalog
          />
        </div>
        <div className="tab">
          <EspaceTab
            messagesNumber={countMessages}
            warningsNumber={0}
            enabled={isEnabled}
            type={t('_my_eshop').toLocaleUpperCase()}
            onClick={() => null}
            description={t('_espace_clients_description')}
            buttonText={t('_espace_clients_button')}
            buttonLink="espace-clients"
            items={props.clientTabItems}
            loading={props.loading}
            from="clients"
          />
        </div>
      </div>
    </div>
  );
};

EspaceView.displayName = 'EspaceView';

export default EspaceView;
