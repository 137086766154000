import { faCartShopping, faCircleArrowLeft, faDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendEmailToSeller from 'applications/automotor/components/espace-automotor/send-email-to-seller/SendEmailToSeller';
import { IImportFileNew } from 'interfaces/price';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';

import { IValueItems } from '../BtnTariff/Proforma';
import { useTranslation } from 'react-i18next';
import { notify } from 'utils/marketplace';
import moment from 'moment';
import TariffTable from '../TariffTable/TariffTable';
import ProformaNew from '../BtnTariff/ProformaNew';
import { useAppSelector } from 'utils/hooks/redux';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import ReactDatePicker from 'react-datepicker';
import TextInput from 'components/inputs/TextInput/TextInput';
import i18n from 'i18n';
import './EspaceTariff.scss';
import Modal from 'components/modal/Modal';
import AddReferenceModal from '../../espace-automotor-order/order-summary/table-section-details/add-reference-modal/AddReferenceModal';
import { setQuantityEspaceAutomotor } from 'store/slices/cartEspaceAutomotor.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ModalRestriction from '../../espace-automotor-order/order-summary-v3/modalRestriction/ModalRestriction';

const EspaceTariff: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userData.user);
  const http = useHttp();
  const { t } = useTranslation();
  const referenceData = JSON.parse(localStorage.getItem('referenceData')!);
  const [disableBtn, setDisableBtn] = useState(false);

  const [data, setData] = useState<IImportFileNew[]>(referenceData || []);
  const [newRefData, setNewRefData] = useState<IImportFileNew[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [total, setTotal] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [valueItems, setValueItems] = useState<IValueItems>();
  const [startDate, setStartDate] = useState(null);
  const [valueDate, setValueDate] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dispatch = useDispatch();
  const { quantityEspaceAutomotor } = useSelector((state: RootState) => state.cartEspaceAutomotor);

  const [submitCountryRestriction, setSubmitCountryRestriction] = useState<boolean>(false);
  const [openModalCountryRestriction, setOpenModalCountryRestriction] = useState<boolean>(false);
  const [dataCountryRestriction, setDataCountryRestriction] = useState<{ [key: string]: [string] }>();

  useOnClickOutside(dateRef, () => setOpenDate(false));

  function onDateChange(date) {
    setStartDate(date);
    setValueDate(`${moment(date).format('DD/MM/YYYY')}`);
  }

  const exportFile = () => {
    setLoadingExport(true);

    if (!data.length) {
      notify(t('_file_could_not_be_downloaded') + ' ' + t('_empty_data'), 'error');
      setLoadingExport(false);

      return;
    }

    http.price
      .export(data)
      .then((res) => {
        const element = document.createElement('a');

        const file = new Blob([res.data], { type: res.type });

        element.href = URL.createObjectURL(file);
        element.download = `${t('_reference')} - ${moment().format('DD.MM.YYYY')}.xlsx`;
        element.click();
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const params = {
      reference: searchParams.get('reference') as string,
      cod_fou: searchParams.get('cod_fou') as string,
    };

    if (data.length === 0 || searchParams.get('new_reference')) {
      http.price
        .getPrice(params)
        .then((res) => {
          setValueItems(undefined);

          // if (searchParams.get('ind_reference')) {

          //   res.data[0].parts[0].ind_equivalence_select = true;
          //   setTotal(res.data[0].parts[0].ind_equivalence && Number(res.data[0].parts[0].ind_equivalence.quantity) >= res.data[0].parts[0].ind_equivalence.min_quantity
          //     && (Number(res.data[0].parts[0].ind_equivalence.quantity) % res.data[0].parts[0].ind_equivalence.uv) == 0 ? Number(res.data[0].parts[0].ind_equivalence.total) : 0);
          // } else {
          //   res.data[0].parts[0].ind_equivalence_select = false;
          //   setTotal(Number(res.data[0].parts[0].quantity) >= res.data[0].parts[0].min_quantity
          //     && (Number(res.data[0].parts[0].quantity) % res.data[0].parts[0].uv) == 0 ? Number(res.data[0].parts[0].total) : 0);
          // }

          setData(res.data);


          if (!localStorage.getItem('referenceData')) {
            localStorage.setItem('referenceData', JSON.stringify(res.data));
          }

          checkRestrictionParts();
        })
        .finally(() => {
          setLoading(false);
          searchParams.delete('new_reference');
          setSearchParams(searchParams);
        }).catch((err) => notify(err.message[0]));
    } else {
      const newData = [...JSON.parse(JSON.stringify(data))];

      // if (searchParams.get('ind_reference')) {

      //   newData[0].parts[0].ind_equivalence_select = true;
      //   setTotal(newData[0].parts[0].ind_equivalence && Number(newData[0].parts[0].ind_equivalence.quantity) >= newData[0].parts[0].ind_equivalence.min_quantity
      //     && (Number(newData[0].parts[0].ind_equivalence.quantity) % newData[0].parts[0].ind_equivalence.uv) == 0 ? Number(newData[0].parts[0].ind_equivalence.total) : 0);
      // } else {
      //   newData[0].parts[0].ind_equivalence_select = false;
      //   setTotal(Number(newData[0].parts[0].quantity) >= newData[0].parts[0].min_quantity
      //     && (Number(newData[0].parts[0].quantity) % newData[0].parts[0].uv) == 0 ? Number(newData[0].parts[0].total) : 0);

      // }

      setData(newData);
      setLoading(false);
      checkRestrictionParts();
    }
  }, [searchParams.get('reference'), searchParams.get('name'), searchParams.get('cod_fou'), searchParams.get('ind_reference'), searchParams.get('new_reference')]);

  useEffect(() => {
    if (newRefData.length) {
      const refDataMap = new Map();

      newRefData.forEach((item) => {
        const { code_fou, parts } = item;

        if (refDataMap.has(code_fou)) {
          const existingItem = refDataMap.get(code_fou);
          const mergedParts = [...existingItem.parts, ...parts];

          refDataMap.set(code_fou, { ...existingItem, parts: mergedParts });
        } else {
          refDataMap.set(code_fou, { ...item });
        }
      });

      const mergedRefData = Array.from(refDataMap.values());

      setData(mergedRefData);
      localStorage.setItem('referenceData', JSON.stringify(mergedRefData));
      setSubmitCountryRestriction(false);
    }
  }, [newRefData]);

  const redirect = () => {
    navigate('/espace-automotor');
  };


  useEffect(() => {
    if (valueItems !== undefined) {
      const newData = [...JSON.parse(JSON.stringify(data))];

      newData.forEach((items: IImportFileNew) => {
        items.parts.forEach((item) => {
          if (item.index === valueItems.index) {
            item.quantity = valueItems.quantity;
            item.total = Number(parseFloat(String(valueItems.quantity * Number(item.price))).toFixed(2));

            if (item.ind_equivalence) {
              item.ind_equivalence.quantity = valueItems.quantity;
              item.ind_equivalence.total = Number(parseFloat(String(valueItems.quantity * Number(item.ind_equivalence.price))).toFixed(2));
            }

          }
        });
      });

      if (newData.length) {
        setData(newData);

        // if (searchParams.get('ind_reference')) {
        //   newData[0].parts[0].ind_equivalence_select = true;
        //   setTotal(newData[0].parts[0].ind_equivalence && Number(newData[0].parts[0].ind_equivalence.quantity) >= newData[0].parts[0].ind_equivalence.min_quantity
        //     && (Number(newData[0].parts[0].ind_equivalence.quantity) % newData[0].parts[0].ind_equivalence.uv) == 0 ? Number(newData[0].parts[0].ind_equivalence.total) : 0);
        // } else {
        //   newData[0].parts[0].ind_equivalence_select = false;
        //   setTotal(Number(newData[0].parts[0].quantity) >= newData[0].parts[0].min_quantity
        //     && (Number(newData[0].parts[0].quantity) % newData[0].parts[0].uv) == 0 ? Number(newData[0].parts[0].total) : 0);
        // }

      }

      localStorage.setItem('referenceData', JSON.stringify(newData));
    }

  }, [valueItems]);


  const changePartInd = (status, index: number) => {
    const obj = [...JSON.parse(JSON.stringify(data))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map(item => {
        if (item.index === index) {
          if (status) {
            item.ind_equivalence_select = true;
          } else {
            item.ind_equivalence_select = false;
          }
        }

        return item;
      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      setData(newData);

      // if (searchParams.get('ind_reference')) {
      //   setTotal(newData[0].parts[0].ind_equivalence && Number(newData[0].parts[0].ind_equivalence.quantity) >= newData[0].parts[0].ind_equivalence.min_quantity
      //     && (Number(newData[0].parts[0].ind_equivalence.quantity) % newData[0].parts[0].ind_equivalence.uv) == 0 ? Number(newData[0].parts[0].ind_equivalence.total) : 0);
      // } else {
      //   setTotal(Number(newData[0].parts[0].quantity) >= newData[0].parts[0].min_quantity
      //     && (Number(newData[0].parts[0].quantity) % newData[0].parts[0].uv) == 0 ? Number(newData[0].parts[0].total) : 0);
      // }
    }

    if (status) {
      if (searchParams.get('ind_reference') == null) {
        searchParams.set('ind_reference', newData[0].parts[0].ind_equivalence.reference);
        setSearchParams(searchParams);
      }

    } else {
      searchParams.delete('ind_reference');
      setSearchParams(searchParams);
    }


  };


  const valueChangeRepaced = (index: number) => {
    const obj = [...JSON.parse(JSON.stringify(data))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map(item => {
        if (item.index === index) {
          const replace = item.replace;
          const itemDefault = { replace_status: !item.replace_status, index: Number(item.index), quantity: item.quantity };

          delete item.replace_status;
          delete item.index;
          delete item.replace;
          delete item.quantity;
          const itemNew = { ...itemDefault, ...replace };

          return { ...itemNew, ...{ replace: item } };
        }

        return item;
      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      setData(newData);
    }
  };

  const removeItemRow = (index: number) => {
    const obj = [...JSON.parse(JSON.stringify(data))];

    obj.forEach((item) => {
      item.parts = item.parts.filter((part) => part.index !== index);
    });

    const newData = obj.filter((item) => item.parts.length !== 0);

    if (newData.length) {
      setData(newData);
      localStorage.setItem('referenceData', JSON.stringify(newData));
    }
  };

  useEffect(() => {
    const hasItemWithZeroQuantity = data.some((item) => {
      return item.parts.some((part) => part.quantity === 0);
    });

    if (hasItemWithZeroQuantity) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [data]);

  function handleAddToCart() {
    setLoadingCart(true);
    let total = 0;


    const params = data.map(item => {

      return item.parts.map(part => {
        total += part.ind_equivalence && part.ind_equivalence_select ? Number(part.ind_equivalence.quantity) : Number(part.quantity);

        if (part.ind_equivalence && part.ind_equivalence_select) {

          return {
            price: part.ind_equivalence.price,
            quantity: part.ind_equivalence.quantity,
            codfou: part.ind_equivalence.codfou,
            source: part.ind_equivalence.source,
            supplier_id: part.ind_equivalence.supplier_id,
            reference: part.ind_equivalence.reference_full,
            reference_tecdoc: part.ind_equivalence.reference
          };
        } else {

          return {
            price: part.price,
            quantity: part.quantity,
            codfou: part.codfou,
            source: part.source,
            supplier_id: part.supplier_id,
            reference: part.reference_full,
            reference_tecdoc: part.reference
          };
        }

      });
    }).flat();


    dispatch(setQuantityEspaceAutomotor(quantityEspaceAutomotor + total));

    http.order.addToCartAol(params).then(() => {
      notify(t('_success'), 'success');
    }).finally(() => setLoadingCart(false));
  }


  const checkRestrictionParts = async () => {
    const country_restriction = {};

    data.map((items) => {
      items.parts.map((item) => {
        if (item.country_restriction) {
          if (typeof country_restriction[item.country_restriction.name] !== 'undefined') {
            country_restriction[item.country_restriction.name].push(item.reference);
          } else {
            country_restriction[item.country_restriction.name] = [];
            country_restriction[item.country_restriction.name].push(item.reference);
          }
        }
      });
    });


    if (Object.keys(country_restriction).length) {
      setDataCountryRestriction(country_restriction);
      setOpenModalCountryRestriction(true);
    } else {
      setSubmitCountryRestriction(true);
    }

  };

  const removeRestrictionParts = () => {


    const newData = data.map((items) => {

      const result_parts = items.parts.filter((item) => {
        if (item.country_restriction && dataCountryRestriction != undefined) {
          return !dataCountryRestriction[item.country_restriction.name].includes(String(item.reference));
        } else {
          return item;
        }
      });

      items.parts = result_parts;

      return items;
    });


    if (newData.length) {
      setData(newData);
      localStorage.setItem('referenceData', JSON.stringify(newData));

    }

  };

  const redirectDelete = () => {
    localStorage.removeItem('referenceData');
    navigate('/espace-automotor');
  };

  return (
    <div className="automotor-tariff-cards">
      <div className="divReturnButton">
        <span className="returnButton" onClick={redirect}>
          <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
          {t('_return')}
        </span>
      </div>
      <div className="automotor-tariff-espace">
        {loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className="tariff-header">
              <div className="tariff-title">{`${t('_summary_of_your_quotation')}`}</div>
              <div className="tariff-btn">
                <div className="tariff-btn-export">
                  <button className="btn-export" type="button" onClick={exportFile}>
                    {loadingExport ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faDownload} className="icon" />}{' '}
                    {t('_export_to_excel')}
                  </button>
                  <button className="btn-export" type="button" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="icon" /> {t('_new_reference')}
                  </button>
                </div>
                <div className="automotor-online">
                  <button className="aol-add_to_cart btn-export" type="button" onClick={handleAddToCart}>
                    {loadingCart ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faCartShopping} className="icon" />} {t('_add_to_cart')}
                  </button>
                  <div className={`tariff-btn-comand ${disableBtn ? 'disable' : ''}`}>
                    <ProformaNew data={data} deliveryDate={startDate} checkRestrictionParts={checkRestrictionParts} submitCountryRestriction={submitCountryRestriction} />
                  </div>
                </div>
              </div>
            </div>

            {!!data.length && !loading &&
              <TariffTable
                loadingContent={loading}
                changePartInd={changePartInd}
                data={data}
                setValueItems={setValueItems}
                valueChangeRepaced={valueChangeRepaced}
                removeRow={removeItemRow}
                redirect={redirectDelete}

              />
            }

            {user?.client_settings.show_proforma_delivery_date ?
              <div className="form-date-input-item">
                <div className="label">
                  {t('_delivery_date')}
                </div>
                <div className={`form-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                  <TextInput readOnly placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
                </div>
                {openDate && (
                  <div className="form-date-picker" ref={dateRef}>
                    <ReactDatePicker
                      onChange={onDateChange}
                      dateFormat="yyyy-MM-dd"
                      minDate={moment().toDate()}
                      inline
                      locale={i18n.language.split('-')[0]}

                    />
                  </div>
                )}
              </div>
              : ''}

            <SendEmailToSeller />
          </React.Fragment>
        )}
      </div>
      <Modal
        openModal={openModal}
        shouldCloseOnOverlayClick={false}
        setOpenModal={setOpenModal}
        childrenComponent={
          <AddReferenceModal tableData={data} setNewRefData={setNewRefData} setOpenModal={setOpenModal} referenceData />
        }
      />

      <Modal
        openModal={openModalCountryRestriction}
        shouldCloseOnOverlayClick={false}
        setOpenModal={setOpenModalCountryRestriction}
        childrenComponent={
          <ModalRestriction
            dataCountryRestriction={dataCountryRestriction}
            setOpenModalCountryRestriction={setOpenModalCountryRestriction}
            removeRestrictionParts={removeRestrictionParts}
            setSubmitCountryRestriction={setSubmitCountryRestriction}

          />
        }
      />
    </div>
  );
};

EspaceTariff.displayName = 'EspaceTariff';

export default EspaceTariff;
