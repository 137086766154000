import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IDataRefAll } from 'utils/hooks/http/search-by-reference-all.http';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import './RefDropdownContentAll.scss';

type Props = {
  refData: IDataRefAll[];
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
};


const RefDropdownContentAll: React.FC<Props> = (props) => {
  const referenceDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();

  useOnClickOutside(referenceDropdownRef, () => props.setIsOpen(false));

  const navigate = useNavigate();


  function openTariff(item: IDataRefAll) {
    localStorage.removeItem('referenceData');

    const searchParams = createSearchParams([
      ['reference', String(item.reference)],
      ['cod_fou', String(item.codfou)],
      ['name', String(item.name)],
      ['source', String(item.source)],
      ['supplier_id', String(item.supplier_id)],
      ['reference_full', String(item.reference_full)],
      ['new_reference', String(item.reference_full)],
    ]);

    navigate({
      pathname: '/espace-automotor/offer/ref-search',
      search: `?${searchParams}`,
    });
    props.setIsOpen(false);
  }

  function openTariffInd(item: IDataRefAll) {
    localStorage.removeItem('referenceData');
    const searchParams = createSearchParams([
      ['reference', String(item.reference)],
      ['cod_fou', String(item.codfou)],
      ['name', String(item.name)],
      ['source', String(item.source)],
      ['supplier_id', String(item.supplier_id)],
      ['reference_full', String(item.reference_full)],
      ['ind_reference', String(item.ind_equivalence?.reference)],
      ['new_reference', String(item.reference_full)],
    ]);

    navigate({
      pathname: '/espace-automotor/offer/ref-search',
      search: `?${searchParams}`,
    });
    props.setIsOpen(false);
  }

  const hasIndReference = props.refData?.filter(item => !!item.ind_equivalence?.reference);

  return (
    <div ref={referenceDropdownRef} className={`ref-dropdown-content-all ${hasIndReference.length ? 'has-ind' : ''}`}>
      <div className={`ref-dropdown-header ${hasIndReference.length ? 'has-ind' : ''}`}>
        <div className="ref-dropdown-title">{t('_search_result')}</div>
        {!!hasIndReference.length && <div className="ref-dropdown-title ind">{t('_best_ind_rate')}</div>}
      </div>
      <div className="ref-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          <React.Fragment>
            {props.refData?.length ? (
              props.refData?.map((item, index) => {
                return (
                  <li key={index} className="suggestion-list-item">
                    <div className={`suggestion-item-title ${hasIndReference.length ? 'has-ind' : ''}`} onClick={() => openTariff(item)}>
                      <div>{item.reference_full}</div>
                      <div>{item.name}</div>
                    </div>
                    {!!hasIndReference.length && <div className="red" onClick={() => openTariffInd(item)}>{item?.ind_equivalence?.reference}</div>}
                  </li>
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
            { }
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

RefDropdownContentAll.displayName = 'RefDropdownContentAll';

export default RefDropdownContentAll;
