import React, { useContext, useEffect} from 'react';
import './DefaultMessages.scss';
import useHttp from 'utils/hooks/useHttp';
import { IGetMessagesParams } from 'interfaces/messaging';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import Message from 'components/message/Message';
import { notify } from 'utils/marketplace';
import { uniqBy } from 'lodash';
import {IMessagesContext, MessagesContext} from '../../../context/MessagesContext';
import useTimerMessages from '../../../utils/hooks/useTimerMessages';
import {deleteMessageFromList, handleScroll, scrollBottom} from '../messages-utils';
import ReplyMessage from '../ReplyMessage/ReplyMessage';

type Props = {
}

const DefaultMessages: React.FC<Props> = () => {
  const { messaging } = useHttp();
  const { t } = useTranslation();
  const {ref, bottom, scrollOnTop,isScrollOnTop, loadingOldMessages,isLoadingOldMessages,pagination,setPagination,  messages, setMessages, files, setFiles, loadingMessages, isLoadingMessages, messageIsCreating, setMessageIsCreating, newMessagesLoading, isNewMessagesLoading, lastMessageId, setLastMessageId} = useContext(MessagesContext) as IMessagesContext;
  const time = useTimerMessages();

  useEffect(() => {

    getMessages();


    return () => {
      setMessages([]);
    };
  }, []);

  useEffect(() => {
    if(!newMessagesLoading ){
      getNewMessages();
    }
  }, [time]);

  useEffect(() => {
    if (scrollOnTop) {
      // aici se reseteaza scroll-ul
      isScrollOnTop(false);

      if (pagination?.total_count > pagination.page * pagination.per_page) {

        if (!loadingMessages) {
          getOldMessages();
        }

      }
    }

  }, [scrollOnTop]);

  const getMessages = async () => {
    if (loadingMessages) {
      return false;
    }

    try {
      isLoadingMessages(true);

      const params: IGetMessagesParams = {
        page: pagination.page,
        per_page: pagination.per_page,
      };

      const { data } = await messaging.getMessages(params);

      if (data.data.length) {
        const uniqueData = uniqBy([...messages, ...data.data].reverse(), 'id');

        setMessages(uniqueData);

        setPagination({
          page: data.page,
          per_page: data.per_page,
          total_count: data.total_count,
          unseen_messages: data.unseen_messages
        });

        if (!lastMessageId) {
          await setLastMessageId(data.data[0].id);
        }
      }

    } catch (e) {
      console.log(e);
    } finally {
      isLoadingMessages(false);
      scrollBottom(ref);

    }
  };

  const createMessage = async (dataMessage: FormData) => {
    setMessageIsCreating(true);

    try {
      await getNewMessages();
      const { data } = await messaging.createMessage(dataMessage);

      setLastMessageId(data.id);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMessages((prevState) => [...prevState, data]);
      // reset({ 'message': '' });
      setFiles([]);
    } catch (e) {
      console.log(e);
      notify(t('_error'));
    } finally {
      setMessageIsCreating(false);
      scrollBottom(ref);
    }
  };


  const getNewMessages = async () => {
    try {

      isNewMessagesLoading(true);

      const { data } = await messaging.getNewMessages(lastMessageId);

      if (data.length) {
        const uniqueData = uniqBy([...messages, ...data], 'id');

        setLastMessageId(data[0].id);

        if (uniqueData.length) {
          setMessages(uniqueData);
        }
      }

    } catch (e) {
      isNewMessagesLoading(false);
    } finally {
      isNewMessagesLoading(false);
    }

  };

  const onSubmit = async (values) => {
    if (messageIsCreating) {
      return;
    }

    const formData: FormData = new FormData();

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }

    formData.append('message', values.message.trim());
    await createMessage(formData);
  };

  const getOldMessages = async () => {

    if (loadingOldMessages || loadingMessages) {

      return false;
    }

    isLoadingOldMessages(true);

    try {

      pagination.page = pagination.page + 1;

      const params: IGetMessagesParams = {
        page: pagination.page,
        per_page: pagination.per_page
      };

      const { data } = await messaging.getMessages(params);

      if (data.data.length) {
        const uniqueData = uniqBy([...data.data.reverse(), ...messages], 'id');
        // const uniqueData = uniqBy([...messages, ...data.data], 'id');

        setMessages(uniqueData);

        setPagination({
          page: data.page,
          per_page: data.per_page,
          total_count: data.total_count,
          unseen_messages: data.unseen_messages
        });

        if (!lastMessageId) {
          await setLastMessageId(data.data[0].id);
        }

      }

    } catch (e) {
      console.log(e);
    } finally {
      isLoadingOldMessages(false);
      setTimeout(() => {
        ref.current.scrollTo({ top: 20 });
      });
    }
  };

  return (
    <div className="automotor-messages-wrapper">
      <div className="container">
        <div className="content">

          <div onScroll={() => handleScroll(loadingMessages, loadingOldMessages, ref, isScrollOnTop)} className="messages" ref={ref}>
            {
              loadingMessages ? (
                <Spinner />
              ) : (
                messages?.length ? (
                  <React.Fragment>
                    <div className="messages-list">
                      {
                        loadingOldMessages && (
                          <div className="loading-old-messages"><Spinner class="small"></Spinner></div>
                        )
                      }
                      {
                        messages?.map((message) => {
                          return (
                            <Message key={message.id} message={message} deleteMessageFromList={() => deleteMessageFromList(messages, message, setMessages)}></Message>
                          );
                        })
                      }
                    </div>
                    <div ref={bottom}></div>
                  </React.Fragment>


                ) : (
                  <p className="no-messages">{t('_no_messages')}</p>
                )
              )
            }
          </div>
          <ReplyMessage onSubmit={onSubmit}/>
        </div>
      </div>
    </div>
  );

};

DefaultMessages.displayName = 'DefaultMessages';

export type IMessageCreate = {
  message: string;
  files: File[];
};


export default DefaultMessages;