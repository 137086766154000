import React, { useEffect, useState } from 'react';
import '../EspaceReliableNavbar.scss';
import AccountDropdown from 'components/dropdowns/AccountDropdown/AccountDropdown';
import CartButton from 'components/buttons/CartButton/CartButton';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import useDefaultRouteForRole from 'utils/hooks/useDefaultRouteForRole';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RELIABLE_LOGO from 'assets/images/reliable-logo.svg';
import Sidenav from '../SidenavMobile/Sidenav';

type Props = {};

const EspaceReliableNavbarMobile: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.userData);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const http = useHttp();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    updateCartQuantityToolbar();
  }, []);

  const updateCartQuantityToolbar = () => {
    http.cart.getCartQuantity().then((res) => {
      dispatch(setQuantityToolbar(res.quantity));
    });
  };

  const handleClick = () => {
    setIsActive(true);
  };

  const defaultLinkForRole = useDefaultRouteForRole();

  return (
    <div className="espace-reliable-navbar-container">
      <div className="navbar">
        <div className="sidebar-mobile">
          <FontAwesomeIcon icon={faBars} className="icon" onClick={handleClick} />
        </div>
        <NavLink to={defaultLinkForRole} className="logos">
          <div className="garage-michel">
            {user?.client_logo && <img className="client-logo" src={user?.client_logo} alt="espace-logo" />}
            <p className="user-name"> {user && user.client_short_name ? <span>{user?.client_short_name}</span> : null}</p>
            <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" />
          </div>

        </NavLink>

        <div className="user-settings">
          <AccountDropdown />
          <CartButton quantityToolbar={quantityToolbar} />
        </div>
        <Sidenav isActive={isActive} setIsActive={setIsActive} />
      </div>
    </div>
  );
};

EspaceReliableNavbarMobile.displayName = 'EspaceReliableNavbarMobile';

export default EspaceReliableNavbarMobile;
