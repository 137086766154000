import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as PriceManagement } from 'assets/images/price-management.svg';


type Props = {
}

const ContentPriceManagement: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (

    < div className="item-price" >
      <NavLink to="/espace-automotor/price/management">
        <div className="title">
          {t('_price_management')}
        </div>
        <div className="icon-wrapper">
          <div className="icon">
            <PriceManagement />
          </div>
          <div className="text">
            {t('_price_management')}
          </div>
        </div>
      </NavLink>
    </div >

  );
};

ContentPriceManagement.displayName = 'ContentPriceManagement';

export default ContentPriceManagement;
