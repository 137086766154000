import React, { useRef, useState } from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';

import { faFileContract, faGear, faHeart, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import './NavbarGroupBtns.scss';
import { RootState } from 'store/store';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {};

const ReliableFloatableButtons: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [buttonsAreaExpanded, setButtonsAreaExpanded] = useState<boolean>(false);
  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const { isMobile } = useDeviceDetect();
  const totalNotificationsNumber = newMessagesNumber + 0;

  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });

  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToFavourites = () => {
    navigate('/favorite');
  };

  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };


  return (
    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} icon={!buttonsAreaExpanded ? faGear : faXmark} />
        <div className={`buttons ${buttonsAreaExpanded ? 'show' : 'hidden'}`}>
          {/* <DefaultIcon onClick={() => null} icon={faSearch} /> */}
          <DefaultIcon onClick={goToFavourites} icon={faHeart} />
          <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} />
        </div>
      </div>
    ) : (
      <React.Fragment>
        {/* <DefaultIcon onClick={() => null} icon={faSearch} text={t('_rapid_search')} /> */}
        <DefaultIcon onClick={goToFavourites} icon={faHeart} text={t('_favorite')} />
        <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} text={t('_catalog_ebooks')} />
      </React.Fragment>
    )
  );
};

ReliableFloatableButtons.displayName = 'ReliableFloatableButtons';

export default ReliableFloatableButtons;
