import React, { useEffect, useState } from 'react';
import CartTable from './CartTable/CartTable';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import './Cart.scss';
import CheckoutModal from 'pages/Cart/Content/CheckoutModal/CheckoutModal';
import { getMarketplaceBaseUrl, isHostPunchOut, notify } from 'utils/marketplace';
import useHttp from 'utils/hooks/useHttp';
import { contentStyle } from 'pages/Cart/Content/CheckoutModal/ContentStyleModal';
import { useForm } from 'react-hook-form';
import { CartTableItem } from './CartTable/CartTable.model';
import { CirrusPunchoutResponse, GetCartResponse, ItemPunchout } from 'models/cart.model';
import Spinner from 'components/spinner/Spinner';
import { CartTableContextProvider } from '../../../../context/CartTableContext';
import { setQuantityToolbar } from '../../../../store/slices/cart.slice';
import { useAppDispatch } from '../../../../utils/hooks/redux';
import CartPunchOutForm from '../../../punchout/components/CartPunchOutForm';
import Pagination from 'components/pagination/Pagination';
import { useSearchParams } from 'react-router-dom';

type Props = {};

const Cart: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const http = useHttp();
  const { getValues, register } = useForm();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [rowLoading, setRowLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [orderId, setOrderId] = useState<null | number>(null);
  const [dataState, setDataState] = useState<CartTableItem[]>([]);
  const [totalPartsPrice, setTotalPartsPrice] = useState<number>(0);
  const [currentItemsPunchOut, setCurrentItemsPunchOut] = useState<ItemPunchout[]>([]);
  const [curentPage, setCurentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const dispatch = useAppDispatch();

  function handleCheckout() {
    //check if external_cart_id exist in localStorage
    const externalCartId = localStorage.getItem('external_cart_id');

    if (externalCartId) {
      cirrusPunchoutCheckout();

      return;
    }

    standardCheckout();
  }


  const cirrusPunchoutCheckout = () => {
    setLoading(true);
    const externalCartId = localStorage.getItem('external_cart_id') ?? undefined;

    http.cart.checkoutCirrus({ customer_id: getValues().order_id, external_cart_id: externalCartId }).then(({ data }) => {
      const { client_erp_id, items, username, password } = data as CirrusPunchoutResponse;

      setCurrentItemsPunchOut(items);
      // document.forms['addBasket'].setAttribute('action', url);
      // document.forms['addBasket'].setAttribute('action', sessionStorage.getItem('callback_order_url') || 'https://cirrus-pro.re/panier');
      document.forms['addBasket']['username'].setAttribute('value', username);
      document.forms['addBasket']['password'].setAttribute('value', password);
      document.forms['addBasket']['client_erp_id'].setAttribute('value', client_erp_id);
      document.forms['addBasket']['cart_id'].setAttribute('value', externalCartId);
      document.forms['addBasket']['default_warehouse'].setAttribute('value', localStorage.getItem('default_warehouse'));

      // setTimeout(()=>{
      // document.forms['addBasket'].submit();
      // });

      // window.history.replaceState(null, 'Amerigo | History', '/');

      // fetchData();
      // updateCartQuantityToolbar();

    }).catch((e) => {
      console.log(e);
      notify('Error');
    }).finally(() => {
      setLoading(false);
    });
  };

  const standardCheckout = () => {
    setLoading(true);
    http.cart.checkout({ customer_id: getValues().order_id }).then(({ data }) => {
      setOrderId(data);
      setOpenModal(true);
      fetchData();
      updateCartQuantityToolbar();

    }).catch(() => {
      notify('Error');
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateCartQuantityToolbar = () => {
    http.cart.getCartQuantity().then((res) => {
      dispatch(setQuantityToolbar(res.quantity));
    });
  };

  const fetchData = async (page = 1) => {
    try {
      setLoadingData(true);
      const response = await http.cart.getCartData(page).finally(() => {
        setLoadingData(false);
      });


      setTotalPage(response.total_pages);
      setCurentPage(response.page);

      const data = response.data.map((item: GetCartResponse): CartTableItem => {
        const images = item.images?.filter(image => {
          const lastDotIndex = image.lastIndexOf('.');
          const extension = image.substring(lastDotIndex + 1).toLocaleLowerCase();

          if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'bmp') {
            return extension;
          }
        });

        const workTimeDescription: string = (item?.work_description ? `<span class="work-time-description">${item?.work_description?.data_supplier_name} ${item?.work_description?.data_supplier_reference} </span>` : '');

        return {
          id: item.cart_id,
          available: item.available,
          image: (images ? images[0] : (item?.image ? item?.image : item?.data_supplier_logo)),
          barCode: { image: `${getMarketplaceBaseUrl()}/${item?.barcode?.image}`, code: item?.barcode?.code },
          categories: item?.category ? item?.category : (item?.subcategory ? item?.subcategory.name : ''),
          quantity_availability: { quantity: item.cart_quantity || 0, availability: typeof (item.available) === 'number' ? item.available : item.available.quantity },
          price: item?.price?.price || 0,
          priceVAT: String(item?.price?.price_with_vat),
          work_id: item?.work_id,
          reference: `${item.data_supplier?.name} ${item.data_supplier_reference} ${workTimeDescription}`,
          data_supplier_reference: item?.data_supplier_reference,
          description: item?.description || '',
          short_description: item?.short_description || ''
        };
      });


      setDataState!(() => data);


      // const partsPrice = data.reduce((acc, item) => acc + item.price * item.quantity_availability.quantity, 0);
      // const partsPrice = data.reduce((acc, item) => acc + item.price * item.quantity_availability.quantity, 0);


      setTotalPartsPrice(response.total_price ? Number(response.total_price) : 0);
    } catch (error) {
      console.log(error);
      setLoadingData(false);
      notify('Error');
      setDataState!(() => []);
    }
  };

  const handlePageClick = async (page: number) => {
    setLoading(true);

    await fetchData(page);
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      fetchData(Number(searchParams.get('page') || 1));
    }, 700);

  }, [i18n.language]);

  return (
    <div className="cart-view-container">

      {
        isHostPunchOut() && <CartPunchOutForm currentOrderItems={currentItemsPunchOut} />
      }
      <CartTableContextProvider>
        <div className={`cart-view-body ${loadingData ? 'loading' : null}`}>
          <Modal
            openModal={openModal!}
            setOpenModal={setOpenModal!}
            childrenComponent={<CheckoutModal orderId={orderId} setOpenModal={setOpenModal} />}
            contentStyle={contentStyle}
          />
          {loadingData ?
            <Spinner class="small" />
            : dataState.length ? (
              <React.Fragment>
                <p className="cart-title">{t('_your_shopping_carts')}</p>
                <CartTable
                  rowLoading={rowLoading}
                  setRowLoading={setRowLoading}
                  handleCheckout={handleCheckout}
                  checkoutLoading={loading}
                  register={register}
                  hiddenColumns={[]}
                  dataState={dataState}
                  setDataState={setDataState}
                  fetchData={fetchData}
                  totalPartsPrice={totalPartsPrice}
                  setTotalPartsPrice={setTotalPartsPrice}
                  setLoading={setLoadingData}
                  loading={loadingData}
                />
                {totalPage > 1 && (
                  <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={curentPage} />
                )}
              </React.Fragment>

            ) : (
              <div className="empty_cart">
                <div className="empty_cart_image">
                  <img src={require('assets/images/empty_cart.png')} alt="empty_cart" />
                </div>
                <div className="empty_cart_title">
                  {t('_your_shopping_carts_empty')}
                </div>
              </div>
            )
          }
        </div>
      </CartTableContextProvider>

    </div>

  );
};

export default Cart;
