
import { faCircleArrowLeft, faFileDownload, faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column, useExpanded, useTable } from 'react-table';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import './EspaceAutomotorFinancial.scss';
import i18n from 'i18n';
import Filter from './Filter/filter';
import { columnTableFinancial } from './ColumnTable/columnTable';
import { IDetailsListFinancial } from 'interfaces/financial';
import ClientInfo from './ClientInfo/clientInfo';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';


const EspaceAutomotorFinancial: React.FC = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const http = useHttp();
  const [data, setData] = React.useState<IDetailsListFinancial[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [isActive, setIsActive] = useState(false);
  const [fiter, setFiter] = useState<{}>({});
  const [loadingExportMobile, setLoadingExportMobile] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.userData);
  const [forcePage, setForcePage] = useState<number>(1);

  const request = (params) => {

    http.financialHttp.getList(params).then((res) => {

      setData(res.data.data);
      setPageCount(res.data.total_pages);
    }).finally(() => {
      setLoading(false);
      setLoadingContent(false);

    });
  };


  useEffect(() => {
    const params = {
      page: forcePage,
      per_page: 10
    };

    setLoading(true);
    request(params);

  }, []);

  const handlePageClick = (page: number) => {
    setLoadingContent(true);
    setForcePage(page);

    const params = { ...fiter, ...{ page, per_page: 10 } };

    request(params);

  };


  const hiddenColumns = isMobile ? ['debit', 'pay', 'date', 'credit', 'id_folder'] : [];

  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  const columnsTable = useMemo(
    (): Column<IDetailsListFinancial>[] => columnTableFinancial(), [user, i18n.language, data, isMobile, isTablet, isDesktop]
  );

  if (!isMobile) {
    columnsTable.shift();
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columnsTable,
    data: data,
    initialState
  }, useExpanded);


  return (
    <div className="form-container-section-account-financial" >
      <div className="container-section-account-financial">

        <div className="returnButton" onClick={() => { navigate(-1); }} >
          <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
          {`${t('_return')}`}
        </div>
        <div className="title">{t('_financial').toLocaleUpperCase()}</div>
        {
          isMobile ?
            <div className="filter-btns">
              <button className="button-filter-financial" onClick={() => setIsActive(true)}>< FontAwesomeIcon icon={faFilter} /> {t('_filter')}</button >

              <button className="export-btn" onClick={() => setLoadingExportMobile(true)} disabled={loadingExportMobile}> {loadingExportMobile ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faFileDownload} className="icon" />} {t('_export')} </button>
            </div>
            :
            null
        }

        <div className={`financial-filter ${isMobile && !isActive ? 'hidden-filter-financial' : 'show-filter-financial'}`}>
          {
            isMobile ?
              <div className="close-btn">
                <FontAwesomeIcon icon={faXmark} onClick={() => { setIsActive(false); }} className="close-btn-icon" />
              </div> :
              null
          }
          <Filter setIsActive={setIsActive} setLoadingExportMobile={setLoadingExportMobile} loadingExportMobile={loadingExportMobile} setForcePage={setForcePage} setFiter={setFiter} setLoading={setLoading} setData={setData} setPageCount={setPageCount} setLoadingContent={setLoadingContent} />
        </div>
        <ClientInfo />
        <div className="table-wrapper">
          {loading ? <Spinner /> :
            <table {...getTableProps()} >

              <thead className="thead">
                {headerGroups.map((headerGroup, key) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                    {headerGroup.headers.map((column, index) => (
                      <th {...column.getHeaderProps()} key={index} className="heading">
                        <div className="table-title">{column.render('Header')}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {loadingContent && data.length ?

                <tbody>
                  <tr>
                    <td colSpan={Object.keys(rows[0].original).length}>
                      <Spinner class="extra-small" /></td>
                  </tr>
                </tbody>
                :
                <tbody {...getTableBodyProps} className="body">

                  {
                    data.length ?

                      rows.map((row) => {

                        prepareRow(row);
                        const { key, ...restRowProps } = row.getRowProps();

                        return (
                          <React.Fragment key={key}>
                            <tr key={key} {...restRowProps} className="body-row-espace-financial">
                              {row.cells.map((cell) => {
                                const { key, ...restCellProps } = cell.getCellProps();

                                return (
                                  <td key={key} {...restCellProps} className="cell">
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                            {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                          </React.Fragment>
                        );
                      }) : <tr className="notFond"><td colSpan={10} >{`${t('_no_data_found')}`}</td></tr>
                  }


                </tbody>

              }

            </table>

          }

        </div>
        {pageCount > 1 && <Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage />}
      </div>
    </div>
  );
};

EspaceAutomotorFinancial.displayName = 'EspaceAutomotorFinancial';

export default EspaceAutomotorFinancial;
