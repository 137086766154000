import React from 'react';

import { Outlet, Route } from 'react-router-dom';
import CatalogVehicleLayout from 'applications/public/layouts/CatalogVehicleLayout/CatalogVehicleLayout';
import CatalogsTabsLayout from 'applications/public/layouts/CatalogsTabsLayout/CatalogsTabsLayout';
import CatalogsPartsPage from 'pages/CatalogVehicle/Catalogs/CatalogsPartsPage/CatalogsPartsPage';
import CatalogsTiresPage from 'pages/CatalogVehicle/Catalogs/CatalogsTiresPage/CatalogsTiresPage';
import TechnicalDocumentationPage from 'pages/CatalogVehicle/TechnicalDocumentation/TechnicalDocumentationPage';
import TechnicalDocumentationLayout from 'applications/amerigo/layouts/technical-documentation-layout';
import useTecRMIModules from 'pages/CatalogVehicle/TechnicalDocumentation/TecRMIModules/tecrmi-modules';
import DiagnosticLayout from 'applications/public/layouts/CatalogVehicleLayout/DiagnosticLayout/DiagnosticLayout';
import ActiaPage from 'pages/CatalogVehicle/Diagnostic/Actia/ActiaPage';
import ErrorCodesLayout from 'applications/public/layouts/CatalogVehicleLayout/ErrorCodesLayout/ErrorCodesLayout';
import TechnicalDocumentation from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/TechnicalDocumentation/TechnicalDocumentation';
import Solutions from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/Solutions/Solutions';
import Catalogs from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/Catalogs/Catalogs';
import DiagnosticErrorCodesLayout
  from '../../applications/public/layouts/CatalogVehicleLayout/DiagnosticLayout/DiagnosticErrorCodesLayout/DiagnosticErrorCodesLayout';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ProtectedRoute from 'components/high-order-components/HasRoles/ProtectedRoute';
import NewPartsList from 'components/NewPartsList/NewPartsList';
import CatalogsOilsPageView from 'pages/CatalogVehicle/Catalogs/CatalogsOilsPage/CatalogsOilsPageView';
import CatalogsConsumablesPageView from 'pages/CatalogVehicle/Catalogs/CatalogsConsumablesPage/CatalogsConsumablesPageView';
import CatalogsBatteriesPageView from 'pages/CatalogVehicle/Catalogs/CatalogsBatteriesPage/CatalogsBatteriesPageView';
// import { isMobile, isTablet } from 'react-device-detect';
import CarPartViewMobile from 'components/partsView/Mobile/CarPartViewMobile';
import CarPartView from 'components/partsView/CarPartView';
import ConsumablesViewMobile from 'applications/retailer/views/ConsumablesView/mobile/ConsumablesViewMobile';
import ConsumablesView from 'applications/retailer/views/ConsumablesView/ConsumablesView';
import BatteriesView from 'applications/retailer/views/BatteriesView/BatteriesView';
import BatteriesViewMobile from 'applications/retailer/views/BatteriesView/Mobile/BatteriesViewMobile';
import OilsViewMobile from 'applications/retailer/views/OilsView/Mobile/OilViewMobile';
import OilsView from 'applications/retailer/views/OilsView/OilsView';
import TyreView from 'applications/retailer/views/TyreView/TyreView';
import TyreViewMobile from 'applications/retailer/views/TyreView/Mobile/TyreViewMobile';
import { useDeviceDetect } from './deviceDetect';

const useCatalogVehicleRoutes = (): JSX.Element => {
  const tecRMIModules = useTecRMIModules();
  const {isMobile, isTablet} = useDeviceDetect();
  const { user } = useSelector((state: RootState) => state.userData);


  return (
    <Route path="/reliable/rmi/:vehicle_id" element={<CatalogVehicleLayout />}>
      <Route path="catalogs" element={<CatalogsTabsLayout />}>
        <Route path="" element={<CatalogsPartsPage />} />
        <Route path="parts" element={<CatalogsPartsPage />} />
        <Route path="parts/part-catalog" element={<NewPartsList />} />
        <Route path="car-parts/view/:id" element={isMobile || isTablet ? <CarPartViewMobile /> : <CarPartView />} />
        <Route path="consumables/view/:id" element={isMobile || isTablet ? <ConsumablesViewMobile /> : <ConsumablesView />} />
        <Route path="batteries/view/:id" element={isMobile || isTablet ? <BatteriesViewMobile /> : <BatteriesView />} />
        <Route path="oils/view/:id" element={isMobile || isTablet ? <OilsViewMobile /> : <OilsView />} />
        <Route path="tyres/view/:id" element={isMobile || isTablet ? <TyreViewMobile /> : <TyreView />} />
        <Route path="parts/parts-by-genart" element={<NewPartsList />} />
        <Route path="tires" element={<CatalogsTiresPage />} />
        <Route path="oils" element={<CatalogsOilsPageView />} />
        <Route path="consumables" element={<CatalogsConsumablesPageView />} />
        <Route path="batteries" element={<CatalogsBatteriesPageView />} />
      </Route>
      <Route path="technical-documentation" element={
        <ProtectedRoute
          isAllowed={!!user && user.client_rmi_modules_type !== 'none'}
        >
          <TechnicalDocumentationLayout />
        </ProtectedRoute>
      }
      >
        <Route path="" element={<TechnicalDocumentationPage />} />
        {tecRMIModules}
      </Route>
      <Route path="error-codes" element={<ErrorCodesLayout />}>
        <Route path="manuals" element={<TechnicalDocumentation />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="catalogs" element={<Catalogs />} />
      </Route>
      <Route path="diagnostic" element={<DiagnosticLayout />}>
        <Route path="" element={<Outlet />} />
        <Route path="actia" element={<ActiaPage />} />
      </Route>
      <Route path="diagnostic/:session_id/error-codes" element={<DiagnosticErrorCodesLayout />}>
        <Route path="manuals" element={<TechnicalDocumentation />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="catalogs" element={<Catalogs />} />
      </Route>

    </Route>
  );
};

export default useCatalogVehicleRoutes;
