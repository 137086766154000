import Parametres from 'pages/Parametres/Parametres';
import Profil from 'pages/Profil/Profil';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountLayout from './layouts/account-layout/AccountLayout';
import MainLayout from './layouts/main-layout/MainLayout';
import EspaceCatalog from './views/espace-catalog/EspaceCatalog';
import EspaceClient from './views/espace-client/EspaceClient';
import EspaceSupplier from './views/espace-supplier/EspaceSupplier';
import Espace from './views/espace/Espace';
import SelectClient from 'applications/automotor/views/select-client/SelectClient';
import History from '../../pages/History/History';
import Favorite from '../../pages/Favorite/Favorite';
import CatalogPage from 'pages/CatalogPage/CatalogPage';
import MainLayoutNoHeader from './layouts/main-layout-no-header/MainLayoutNoHeader';
import NewLogin from 'views/NewLogin/NewLogin';
import useAuthCheck from 'utils/hooks/useAuthCheck';
import ProtectedRoute from 'components/high-order-components/HasRoles/ProtectedRoute';
import { ROLE_CLIENT_PLATFORM_ADMIN, ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR } from 'utils/constants/roles';
import useForceSellerSelectClient from 'utils/hooks/Automotor/useForceSupplierSelectClient';
import MySuppliers from 'pages/MySuppliers/MySuppliers';
import Orders from 'pages/Orders/Orders';
import OrderView from 'pages/OrderView/OrderView';
import EspaceTariffs from 'pages/Tariffs/EspaceTariffs';
import CatalogCarParts from 'pages/CatalogKawe/CatalogCarParts';
import CatalogLayout from 'pages/CatalogPage/CatalogLayout';
import EspaceAutomotorLayout from './layouts/espace-automotor-layout/EspaceAutomotorLayout';
import EspaceAutomotor from './views/espace-automotor/EspaceAutomotor';
import EspaceAutomotorMenuTopLayout from './layouts/espace-automotor-layout/espace-automotor-menu-top-layout/EspaceAutomotorMenuTopLayout';
import EspaceAutomotorOffer from './views/espace-automotor-offer/EspaceAutomotorOffer';
import useCommonRoutes from 'utils/hooks/use-common-routes';
import EspaceAutomotorOrder from './views/espace-automotor-order/EspaceAutomotorOrder';


import EspaceAutomotorAccount from './views/espace-automotor-account/EspaceAutomotorAccount';
import OrderList from './views/espace-automotor-order/order-list/OrderList';
import EspaceAutomotorRemaining from './views/espace-automotor-remaining/EspaceAutomotorRemaining';
import EspaceTariff from './views/espace-automotor-offer/Tariff/EspaceTariff';
import TariffImport from './views/espace-automotor-offer/TariffImport/TariffImport';
import OrderDetails from './views/espace-automotor-order/order-list/order-details/OrderDetails';
import useDistributorClientsRoutes from 'utils/hooks/use-distributor-clients-routes';
import AutomotorMessagesPage from './AutomotorMessagesPage/AutomotorMessagesPage';
import useCatalogVehicleRoutes from 'utils/hooks/use-catalog-vehicle-routes';
import CarPartView from 'components/partsView/CarPartView';
import NewsPage from 'pages/NewsPage/NewsPage';
import ManagerGroupSelectClient from './views/manager-group-select-client/ManagerGroupSelectClient';
import useForceClientAutomotorManagerGroupSelectClient from 'utils/hooks/Automotor/useForceClientAutomotorManagerGroupSelectClient';
import EspaceAutomotorFinancial from './views/espace-automotor-financial/EspaceAutomotorFinancial';
import ProformaMapping from './views/espace-automotor-proforma-mapping/ProformaMapping';
import EspaceAutomotorTariffs from './views/espace-automotor-tariffs/EspaceAutomotorTariffs';
import GenartCarParts from 'pages/GenartCarParts/GenartCarParts';
import Ebook from '../../components/Ebook';
import Cart from './views/Cart/Cart';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CarPartViewMobile from 'components/partsView/Mobile/CarPartViewMobile';
import NewsView from 'components/news/news-view/NewsView';
import Promotions from 'pages/Promotions/Promotions';
import Providers from './views/espace-automotor-providers/Providers';
import Notifications from './views/notifications/Notifications';
import PartsListV3 from './components/PartsListV3/PartsListV3';
import PartsListV2 from 'components/PartsListV2/PartsListV2';
import Industrial from './views/espace-automotor-industrial/Industrial';
import CartEspaceAutomotor from './views/CartEspaceAutomotor/CartEspaceAutomotor';
import OrderSummaryV3 from './views/espace-automotor-order/order-summary-v3/OrderSummaryV3';
import CountryRestriction from 'pages/CountryRestriction/CountryRestriction';
import AllowedList from 'pages/AllowedList/AllowedList';
import AllowedListDetails from 'pages/AllowedList/Details/AllowedListDetails';
import EspaceAutomotorPrice from './views/espace-automotor-price/EspaceAutomotorPrice';
import PriceManagement from 'pages/PriceManagement/PriceManagement';

import PriceGroup from 'pages/PriceGroup/PriceGroup';
import EspaceAutomotorClient from './views/espace-automotor-client/EspaceAutomotorClient';
import PartsReplacement from 'pages/PartsReplacement/PartsReplacement';
import EspaceAutomotorPriceHistory from './views/espace-automotor-price/History/EspaceAutomotorPriceHistory';


const Automotor: React.FC = () => {
  useAuthCheck();
  useForceSellerSelectClient();
  useForceClientAutomotorManagerGroupSelectClient();
  const distributorClientsRoutes = useDistributorClientsRoutes();
  const catalogVehicleRoutes = useCatalogVehicleRoutes();
  const commonRoutes = useCommonRoutes();
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <Routes>
      <Route path="/flipbook" element={<Ebook />} />
      <Route path="/espace-automotor" element={<EspaceAutomotorLayout />}>
        <Route path="" element={<EspaceAutomotor />} />
        <Route element={<EspaceAutomotorMenuTopLayout />}>
          <Route path="proforma-mapping" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><ProformaMapping /></ProtectedRoute>} />
          <Route path="providers" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><Providers /></ProtectedRoute>} />
          <Route path="industrial" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><Industrial /></ProtectedRoute>} />

          <Route path="offer" element={<EspaceAutomotorOffer />} />
          <Route path="offer/import" element={<TariffImport />} />
          <Route path="offer/ref-search" element={<EspaceTariff />} />
          <Route path="offer/cart" element={<CartEspaceAutomotor />} />
          <Route path="my-account" element={<EspaceAutomotorAccount />} />
          <Route path="promotions" element={<Promotions />} />
          <Route path="country-restriction" element={<ProtectedRoute roles={[ROLE_SELLER]}><CountryRestriction /></ProtectedRoute>} />
          <Route path="allowed-list" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><AllowedList /></ProtectedRoute>} />

          <Route path="price" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><PriceGroup /></ProtectedRoute>} />
          <Route path="price/management" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><PriceManagement /></ProtectedRoute>} />
          <Route path="price/replacement" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><PartsReplacement /></ProtectedRoute>} />
          <Route path="price/loading-providers" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><EspaceAutomotorPrice /></ProtectedRoute>} />
          <Route path="price/loading-providers/history/:id" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><EspaceAutomotorPriceHistory /></ProtectedRoute>} />
          <Route path="price/tariffs" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><EspaceAutomotorTariffs /></ProtectedRoute>} />

          <Route path="allowed-list/:id" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><AllowedListDetails /></ProtectedRoute>} />
          <Route path="notifications" element={<Notifications />} />

          {/* <Route path="my-account" element={<EspaceAutomotorTabAccount />} /> */}
          {/* <Route path="my-account/remaining" element={<EspaceAutomotorRemaining />} /> */}
          <Route path="my-account/info" element={<EspaceAutomotorAccount />} />
          <Route path="order" element={<EspaceAutomotorOrder />} />
          <Route path="order/summary" element={<OrderSummaryV3 />} />
          <Route path="order/remaining" element={<EspaceAutomotorRemaining />} />
          <Route path="order/financial" element={<EspaceAutomotorFinancial />} />
          <Route path="order/list" element={<OrderList />} />
          <Route path="order/details/:id" element={<OrderDetails />} />
          <Route path="area" element={<EspaceAutomotorClient />} />
          <Route path="area/select" element={<SelectClient slider />} />
          <Route path="area/waiting-offers" element={<SelectClient slider />} />
          <Route path="area/waiting-confirmation" element={<SelectClient slider />} />
          <Route path="area/waiting-confirmation/history-orders" element={<SelectClient slider />} />
          <Route path="area/waiting-confirmation/history-orders/:id" element={<SelectClient slider />} />
        </Route>
      </Route>

      <Route element={<MainLayout />}>
        <Route path="/proforma-mapping" element={<ProformaMapping />}></Route>
        {catalogVehicleRoutes}
        {distributorClientsRoutes}
        <Route path="/reliable/:vehicle_id/car-parts-by-genart" element={<GenartCarParts />} />
        <Route path="/universal-catalog" element={<EspaceCatalog />} />
        {/* <Route path="/car-parts" element={<PartsList />} /> */}
        <Route path="/car-parts" element={<PartsListV3 />} />
        <Route path="/retailer/car-parts" element={<PartsListV2 />} />
        <Route path="/car-parts/view/:id" element={isMobile || isTablet ? <CarPartViewMobile /> : <CarPartView />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:id" element={<NewsView />} />

        <Route
          path="/espace-clients"
          element={
            <ProtectedRoute roles={[ROLE_CLIENT_PLATFORM_ADMIN]}>
              <EspaceClient />
            </ProtectedRoute>
          }
        >
        </Route>

        <Route element={<CatalogLayout />}>
          <Route path="/catalog/:id/parts" element={<CatalogCarParts></CatalogCarParts>}></Route>
          <Route path="/catalog/:id/parts/:q" element={<CatalogCarParts></CatalogCarParts>}></Route>
          <Route path="/catalog/:id" element={<CatalogPage />}></Route>
          <Route path="/catalog" element={<CatalogPage />}></Route>
        </Route>
        <Route path="/reliable" element={<EspaceCatalog />} />
        <Route path="/client" element={<EspaceClient />} />
        <Route path="/supplier" element={<EspaceSupplier />} />
        <Route path="/my-suppliers" element={<MySuppliers />} />
        <Route path="/espace-tariffs" element={<EspaceTariffs />} />
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:order_id" element={<OrderView />} />
        <Route element={<AccountLayout />}>
          <Route path="/my-profile" element={<Profil />} />
          <Route path="/messages" element={<AutomotorMessagesPage />} />
          <Route path="/settings" element={<Parametres />} />
          <Route path="/history" element={<History />} />
          <Route path="/favorite" element={<Favorite />} />
        </Route>
        <Route path="/promotions" element={<ProtectedRoute roles={[ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]}><Promotions /></ProtectedRoute>} />

      </Route>
      <Route element={<MainLayoutNoHeader />}>
        <Route path="/" element={<Espace />} />
      </Route>
      <Route path="/select-client" element={<SelectClient />} />
      <Route path="/waiting-offers" element={<SelectClient />} />
      <Route path="/waiting-confirmation" element={<SelectClient />} />
      <Route path="/waiting-confirmation/history-orders" element={<SelectClient />} />
      <Route path="/waiting-confirmation/history-orders/:id" element={<SelectClient />} />

      <Route path="/manager-group-select-client" element={<ManagerGroupSelectClient />} />
      <Route path="/login" element={<NewLogin />} />
      {commonRoutes}
    </Routes>
  );
};

Automotor.displayName = 'Automotor';

export default Automotor;
